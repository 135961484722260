import { useContext, useEffect, useState } from "react";
import Button from 'react-bootstrap/Button';
import { Stack, UserRole } from "core/models";
import { TabbedStackList } from "stack/stack-list";
import StackPageHeader from 'stack/stack-page-header';
import { fetchScreens, fetchStacks, fetchUserRoles } from "api/api";
import LoadingSpinner from "core/loading-spinner";
import { useNavigate } from "react-router-dom";
import NewStackForm from "./new-stack-form";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { UserContext } from "core/user-account";

interface iStackPageState {
  loading: boolean;
  adding: boolean;
  stacks: Array<Stack>;
  selectedIndex: number,
  roles: Array<UserRole>,
}

/// Fetches and displays available stacks, navigating to the screen selection page when a stack is selected
const StackPage = () => {
  const navigate = useNavigate();
  const [state, setState] = useState<iStackPageState>({ loading: true, adding: false, stacks: [], selectedIndex: 0, roles: []});
  const user = useContext(UserContext);

  /// on mounted, get the stacks
  useEffect(() => {
    console.debug('StackPage ----- Mounted', 'Refreshing Stacks ');
    fetchUserRoles(user.objectId)
      .then((fetched) => {
        setState({
          ...state,
          roles: fetched,
        });
        refreshStacks();
      });
  }, []);

  function refreshStacks() {
    if (!state.loading) {
      setState({
        ...state,
        loading: true,
      });
    }
    fetchStacks()
      .then((fetched) => {
        setState({
          ...state,
          loading: false,
          stacks: fetched,
          selectedIndex: 0,
        });
      })
      .catch((e) => {
        console.error('error fetching stacks', e);
        setState({
          ...state,
          loading: false,
          selectedIndex: 0,
        });
      });
  }

  async function onStackSelected(stack: Stack) {
    //if weekly auto navigate to the rating page
    if (stack.stackType.id == 3) {
      var screens = await fetchScreens(stack.id);
      if (screens && screens.length == 1) {
        navigate(`/${stack.id}/${screens[0].id}`);
        return;
      }
    }
    navigate(`/${stack.id}`);
  }

  function onAddStackClick() {
    setState({
      ...state,
      adding: true,
    });
  }

  function hideNewStackForm() {
    setState({
      ...state,
      adding: false,
    });
  }

  function onStackAdded() {
    hideNewStackForm();
    refreshStacks();
  }

  console.log(`StackPage ----- ${(state.loading) ? 'Loading' : 'Render'}`, state);
  return (
    <div style={{ padding: '10px 12px' }}>
      {(state.adding)
        ? <NewStackForm onCancel={hideNewStackForm} onAdd={onStackAdded} />
        : (
          <StackPageHeader>
            <Button onClick={refreshStacks} variant="outline-primary">
              REFRESH
            </Button>
            <Button onClick={onAddStackClick} style={{ marginLeft: '5px' }}>
              Upload
            </Button>
          </StackPageHeader>
        )}
      {(state.loading)
        ? <LoadingSpinner />
        : <TabbedStackList stacks={state.stacks} onStackSelected={onStackSelected} enableKeyBindings={!state.adding} refreshStacks={refreshStacks}/>}
    </div>
  );
}

export default StackPage;