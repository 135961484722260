import { memo, useEffect, useState } from 'react';
import 'core/app.css';
import GraphClient from "api/custom-graph-client";
import ScreenPage from 'screen/screen-page';
import StackPage from 'stack/stack-page';
import RatingPage from 'rating/rating-page';
import SummaryPage from 'summary/summary-page';
import { Route, Routes } from 'react-router-dom';
import { UserAccount, UserContext } from './user-account';
import { fetchUserRoles } from 'api/api';

const App = (props: {
  token: string,
  user: string,
  objectId: string,
}) => {
  // A client used to connect to Microsoft Graph API for uploading duponts to the user's OneDrive 
  const graphClient = new GraphClient(props.token);
  const [userAccount, setUserAccont] = useState<UserAccount>(new UserAccount(props.user, props.objectId));
  
  useEffect(() => {
    console.debug('StackPage ----- Mounted', 'Refreshing Stacks ');
    fetchUserRoles(props.objectId)
      .then((fetched) => {
        setUserAccont({
          ...userAccount,
          roles: fetched,
        });
      });
  }, []);

  return (
    <UserContext.Provider value={userAccount}>
      {/* Routes nest inside one another. Nested route paths build upon
        parent route paths, and nested route elements render inside
        parent route elements. See the note about <Outlet> below. */}
      <Routes>
        <Route path="/" element={<StackPage />} />
        <Route path="/:stackId" element={<ScreenPage />} />
        <Route path="/:stackId/:screenId" element={<RatingPage /> } />
        <Route path="/:stackId/:screenId/:dupontId" element={<RatingPage /> } />
        <Route path="/:stackId/:screenId/summary" element={<SummaryPage />} /> 
        {/* Using path="*"" means "match anything", so this route
          acts like a catch-all for URLs that we don't have explicit
          routes for. */}
        <Route path="*" element={<div>404 Page Not Found</div>} />
      </Routes>
    </UserContext.Provider>
  );
}

export default memo(App);