import { faSearchMinus, faSearchPlus, faArrowsAltH } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UserPreferences } from "core/models";
import { useContext, useState } from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { UserPreferencesContext } from "./user_preference_context";

type Zoom = {
    scale: number,
    percentage: number,
}

const PdfZoomControls = (props: {
    ratingPageDispatch: (action: any) => void;
    // onUpdateUserPreferences: (userPref:UserPreferences) => void;
}): JSX.Element => {
    const userPreferences = useContext(UserPreferencesContext);
    const zoomMin = 50, zoomMax = 200, zoomDelta = 10;
    const scaleMin = 1, scaleDelta = 0.2;

    const defaultZooms = (): Array<Zoom> => {
        let scale = scaleMin, percentage = zoomMin;
        let zoomDetails: Array<Zoom> = [];
        while (percentage < zoomMax) {
            zoomDetails.push({
                scale,
                percentage,
            });
            scale = parseFloat((scale + scaleDelta).toFixed(1));
            percentage += zoomDelta;
        }
        return zoomDetails;
    }

    function updateUserPreferences(preferences: UserPreferences) {
        props.ratingPageDispatch({ type: 'update_user_preferences', payload: preferences });
    }

    function ConvertZoomToScale(toConvert: number): number {
        const closestMatchingZoom = defaultZooms().reduce((a, b) => (Math.abs(a.percentage - toConvert) < Math.abs(b.percentage - toConvert)) ? a : b);
        return closestMatchingZoom.scale;
    }

    function onDecrementZoom() {
        if (!userPreferences.fitToWidth) {
            var newZoom = Math.max(zoomMin, userPreferences.zoom - zoomDelta);
            if (userPreferences.zoom != newZoom) {
                updateUserPreferences({
                    ...userPreferences,
                    zoom: newZoom,
                    scale: ConvertZoomToScale(newZoom),
                });
            }
        }
    }

    function onIncrementScale() {
        if (!userPreferences.fitToWidth) {
            var newZoom = Math.min(zoomMax, userPreferences.zoom + zoomDelta);
            if (userPreferences.zoom != newZoom) {
                updateUserPreferences({
                    ...userPreferences,
                    zoom: newZoom,
                    scale: ConvertZoomToScale(newZoom),
                });
            }
        }
    }

    function onSelectZoom(e: any) {
        var scale = Number(e.target.value);
        var newZoom = defaultZooms().find(x => x.scale == scale)?.percentage;
        if (newZoom != null && userPreferences.zoom != newZoom) {
            //convert the scale to a zoom
            updateUserPreferences({
                ...userPreferences,
                zoom: newZoom,
                scale: scale,
            });
        }
    }

    function onToggleFit() {
        updateUserPreferences({
            ...userPreferences,
            fitToWidth: !userPreferences.fitToWidth,
        });
    }

    let zoomIconColor = userPreferences.fitToWidth ? 'grey' : 'black';
    return (
        <div className='flex-grow-1' style={{ display: 'flex', alignItems: 'center', paddingRight: '10px', borderRight: '1px solid grey', minWidth: '120px', minHeight: '38px' }}>
            <OverlayTrigger placement="bottom" overlay={<Tooltip>Zoom Out</Tooltip>} delay={{ show: 200, hide: 0 }} rootClose={true}>
                <FontAwesomeIcon icon={faSearchMinus} style={{ marginRight: '5px' }} cursor='pointer' onClick={onDecrementZoom} color={zoomIconColor} />
            </OverlayTrigger>
            <OverlayTrigger placement="bottom" overlay={<Tooltip>Zoom In</Tooltip>} delay={{ show: 200, hide: 0 }} rootClose={true} >
                <FontAwesomeIcon icon={faSearchPlus} style={{ marginRight: '8px' }} cursor='pointer' onClick={onIncrementScale} color={zoomIconColor} />
            </OverlayTrigger>
            <OverlayTrigger placement="bottom" overlay={<Tooltip>Select Zoom</Tooltip>} delay={{ show: 200, hide: 0 }} rootClose={true}>
                <select onChange={onSelectZoom} value={ConvertZoomToScale(userPreferences.zoom)} style={{ marginRight: '5px' }} disabled={userPreferences.fitToWidth}>
                    {defaultZooms().map((zoom: Zoom) => (
                        <option value={zoom.scale} key={zoom.scale}>
                            {zoom.percentage}%
                        </option>
                    ))}
                </select>
            </OverlayTrigger>
            <OverlayTrigger placement="bottom" overlay={<Tooltip>Toggle Fit to Width</Tooltip>} delay={{ show: 200, hide: 0 }} rootClose={true}>
                <Button size='sm' variant={((userPreferences.fitToWidth) ? 'primary' : 'secondary')} onClick={onToggleFit} >
                    <FontAwesomeIcon icon={faArrowsAltH} cursor='pointer' />
                </Button>
            </OverlayTrigger>
        </div>
    );
}
export default PdfZoomControls;