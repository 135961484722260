export class Stack {
    constructor(
        public name: string,
        public id: number = 0,
        public dupontGroupId: number = 0,
        public stackType: StackType,
        public createdOn: Date,
    ) { }
}

export class ScoresByStack {
    constructor(
        public stackName: string,
        public screenName: string,
        public score: string,
        public createdBy: string,
        public ticker: string,
    ) { }
}

export class StackType {
    constructor(
        public id: number,
        public name: string,
        public description: string,
    ) { }
}

export class Screen {
    constructor(
        public stackId: number,
        public stackName: string,
        public id: number,
        public dupontGroupId: number,
        public name: string,
        public status: string,
        public createdBy: string,
        public createdOn: Date,
        public updatedOn: Date,
        public rankTier: RankTier,
        public isComplete: boolean,
    ) { }

}

export class Score {
    constructor(
        public id: number,
        public rankId: number,
        public value: string,
        public dupontId: number,
    ) { }
}

export class DupontGroup {
    constructor(
        public id: number,
        public name: string,
    ) { }
}

export class Dupont {
    constructor(
        public id: number,
        public ticker: string,
        public fileLastModified: Date,
        public fileName: string,
    ) { }
}

export class RankTier {
    constructor(
        public id: number,
        public name: string,
        public description: string,
        public ranks: Array<Rank>,
    ) { }
}

export class Rank {
    constructor(
        public id: number,
        public rankTierId: number,
        public value: string,
        public foregroundColor: string,
        public backgroundColor: string,
        public borderColor: string,
    ) { }
}

export class UserPreferences {
    constructor(
        public zoom: number = 100,
        public scale: number = 2,
        public fitToWidth: boolean = true,
    ) { }
}

export class UserRole {
    constructor(
        public roleId: number,
        public roleDescription: string,  
    ) {}
}
export const ParseStack = (json: any) =>
    new Stack(
        // json['DriveItemId'],
        json.name,
        json.id,
        json.dupontGroupId,
        ParseStackType(json.stackType),
        ParseDate(json.createdOn),
    )

export const ParseStackType = (json: any) =>
    new StackType(
        json.id,
        json.name,
        json.description,
    )

export const ParseScreen = (json: any) => {
    return new Screen(
        json.stackId,
        json.stackName,
        json.id,
        json.dupontGroupId,
        json.name,
        json.status,
        json.createdBy,
        ParseDate(json.createdOn),
        ParseDate(json.updatedOn),
        json.rankTier,
        json.status == 'Complete',
    );
}

export const ParseDupont = (json: any) => {
    return new Dupont(
        json.id,
        json.ticker,
        new Date(Date.parse(json.fileLastModified)),
        json.fileName,
    );
}

export const ParseScore = (json: any) => {
    return new Score(
        json.id,
        json.screenId,
        json.value,
        json.dupontId,
    );
}

export const ParseScoreByStack = (json: any) =>
    new ScoresByStack(
        json.stackName,
        json.screenName,
        json.score,
        json.createdBy,
        json.ticker
    )

export const parseUserPreferences = (json: any) =>
    new UserPreferences(
        json.zoomPercentage,
        json.zoomScale,
        json.fitToWidth,
    )
    
export const parseUserRole = (json: any) =>
new UserRole(
    json.roleId,
    json.roleDescription,
)
/* Parse UTC Date that lacks Zulu timezone (2023-04-14T17:23:10.2520912) */
const ParseDate = (dateJson: any) => {
    // Manually add the zulu timezone code to properly parse UTC date
    return new Date(Date.parse(dateJson + 'Z'));
}