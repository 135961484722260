import { UserContext } from "core/user-account";
import { useContext } from "react";

const StackPageHeader = (props: {
    children?: React.ReactNode;
}) => {
    const userAccount = useContext(UserContext);
    return (
        <div>
            <h3 style={{ margin: 0 }}>Welcome {userAccount.userName}!</h3>
            <h4 style={{ marginTop: 10, marginBottom: 5, }}>Select a Stack</h4>
            <div style={{ marginBottom: 20 }}>Think of a "stack" like a stack of papers. In this case, a stack of duponts.</div>
            {props.children}
        </div>
    );
};

export default StackPageHeader;