import { Dupont } from "core/models";
import DupontIndexControl from "./dupont-index-control";
import DupontSearchControl from "./dupont-search-control";
import { RatingPageState } from "./rating-page";

interface iDupontControls {
    dupontIndex: number;
    enableSearch: boolean;
    ratingPageDispatch: (action:any) => void;
    getNextUnscoredDupont: () => Dupont|null;
    pageState: RatingPageState;
}

const DupontControls = (props: iDupontControls): JSX.Element => {
    let minWidth: string = (props.enableSearch) ? '230px' : '180px';
    return (
        <div style={{ display: 'flex', alignItems: 'center', paddingRight: '10px', marginRight: '10px', borderRight: '1px solid grey', minWidth: minWidth, maxWidth: '275px' }}>
            <DupontIndexControl dupontIndex={props.dupontIndex} ratingPageDispatch={props.ratingPageDispatch} getNextUnscoredDupont={props.getNextUnscoredDupont}/>
            {props.enableSearch && <DupontSearchControl ratingPageDispatch={props.ratingPageDispatch} pageState={props.pageState}/>}
        </div>
    );
}

export default DupontControls;