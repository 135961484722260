import { createContext } from "react";
import { UserRole } from "./models";


/// Specifying "<Type | undefined>" as the createContext type to avoid passing a default unused to the createContext, 
/// the default value should be specified in app.tsx in the useState/useReducer hook initial value param.
export class UserAccount {
    constructor(
        public userName: string = '',
        public objectId: string = '',
        public roles: Array<UserRole> = [],
    ) { }
}
export const UserContext = createContext<UserAccount>(new UserAccount());