import { useContext, useEffect, useState } from "react";
import { ScoresByStack, Screen } from "core/models";
import NewScreenForm from "screen/new-screen-form";
import Button from 'react-bootstrap/Button';
import { CSVLink } from "react-csv";
import LoadingSpinner from "core/loading-spinner";
import { addScreen, fetchScreens, fetchStackScoreSummary } from "api/api";
import PageBreadcrumbs from "core/page-breadcrumbs";
import TabbedScreenList from "screen/screen-list";
import { UserContext } from "core/user-account";
import { useNavigate } from "react-router-dom";
import { useRouteDetails } from "core/route_details";

interface iScreenPageState {
    loading: boolean; //true when fetching data from API 
    stackName: string;
    screens: Array<Screen> | undefined;
    adding: boolean;  //true when the user is in the process of filling the new screen form fields
}

// From this page you can either:
//  - Create a new screen
//  - Continue an inprogress Screen or View a complete screen
//  - Get a score summary of all of the completed screens
const ScreenPage = () => {

    const navigate = useNavigate();
    const [stackId] = useRouteDetails();
    const user = useContext(UserContext);
    const [state, setState] = useState<iScreenPageState>({
        loading: true,
        adding: false,
        stackName: '',
        screens: [],
    });

    useEffect(() => {
        fetchScreens(stackId)
            .then((fetched) => {
                setState({
                    ...state,
                    loading: false,
                    screens: fetched,
                });
            })
            .catch((_) => {
                console.error('error thrown fetching screens');
                //todo handle errors
                setState({
                    ...state,
                    loading: false,
                    screens: [],
                });
            })
    }, []);

    function onScreenSelected(screen: Screen) {
        if (screen.isComplete) {
            navigate(`/${stackId}/${screen.id}/summary`);
        } else {
            navToRating(screen);
        }
    }

    function navToRating(screen: Screen) {
        navigate(`/${stackId}/${screen.id}`);
    }

    async function onAddScreen(screenName: string, rankTierId: number) {
        let screen = await addScreen(stackId, screenName, rankTierId, user.userName);
        setState({
            ...state,
            screens: [
                ...(state.screens ?? []),
                screen,
            ],
        });
        navToRating(screen);
    }

    console.log(`ScreenPage ----- ${(state.loading) ? 'Loading' : 'Render'}`, state);
    return (
        <div style={{ padding: '12px' }}>
            {/*Header*/}
            <PageBreadcrumbs />
            {(state.loading)
                /// Loading
                ? <LoadingSpinner />
                /// Invalid data
                : (state.screens == null) ? <div>ERROR 400 - Invalid Stack ID ({stackId})</div>
                    : <div>
                        {(state.screens != null && state.screens.length > 0) &&
                            <h1>{state.screens[0].stackName}</h1>
                        }
                        <div>
                            {(state.adding)
                                // New screen
                                ? <NewScreenForm
                                    onCancel={() => setState({ ...state, adding: false })}
                                    onAddScreen={onAddScreen} />
                                // Screen Selection
                                : <ScreenSelectionSection
                                    stackName={state.stackName}
                                    onNewScreenClicked={() => setState({ ...state, adding: true })} />
                            }
                        </div>
                        <div style={{ marginTop: "15px", maxWidth: '500px' }}>
                            <TabbedScreenList screens={state.screens} onScreenSelected={onScreenSelected} />
                        </div>
                    </div>
            }
        </div>
    );
}

const ScreenSelectionSection = (props: {
    stackName: string,
    onNewScreenClicked: React.MouseEventHandler<HTMLButtonElement>,
}) => {
    return <>
        <h2>
            Select a Screen
        </h2>
        <div style={{marginBottom: '10px'}}>
            Complete in-progress screens or review completed screens.
        </div>
        <div style={{ display: 'flex' }}>
            <Button variant='outline-primary'
                onClick={props.onNewScreenClicked} >
                NEW SCREEN
            </Button>
            <ScoreSummaryDownloadButton stackName={props.stackName} />
        </div>
    </>
}

const ScoreSummaryDownloadButton = (props: { stackName: string }) => {
    const [stackId] = useRouteDetails();
    const [isDownloading, setDownloading] = useState<boolean>(false);
    const [stackScores, setStackScores] = useState<Array<ScoresByStack> | undefined>();
    const headers = [
        { label: "Stack Name", key: "stackName" },
        { label: "Screen Name", key: "screenName" },
        { label: "Score", key: "score" },
        { label: "Created By", key: "createdBy" },
        { label: "Ticker", key: "ticker" }
    ];

    async function startScoreDownload() {
        setDownloading(true);
        let downloadedScores = await fetchStackScoreSummary(stackId);
        if (downloadedScores == undefined) {
            alert('There was an error downloading the scores for this stack, please try again.');
        } else if (downloadedScores.length == 0) {
            alert('No duponts have been scored, complete a screen to continue.');
        } else {
            setStackScores(downloadedScores);
        }
        setDownloading(false);
    }

    let body;
    if (!stackScores) {
        body = (isDownloading) ? <LoadingSpinner /> : 'Get Score Summary';
    } else {
        body = <CSVLink data={stackScores!} headers={headers} filename='Stack Summary.csv' style={{ color: 'white' }}>Download Summary</CSVLink>;
    }

    return (<div style={{ marginLeft: '5px' }}>
        <Button onClick={() => startScoreDownload()} disabled={isDownloading}>
            {body}
        </Button>
    </div>)
}
export default ScreenPage;