import { Screen } from "core/models";
import { Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

/** A card displaying the Screen Name and a button which triggers onScreenSelected*/
const ScreenCard = (props: {
    screen: Screen;
    onScreenSelected: (s: Screen) => void;
}) => {
    const s = props.screen;
    const dtFormatOptions: Intl.DateTimeFormatOptions = {
        year: '2-digit',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: '2-digit',
    };
    let isUpdated = s.updatedOn > s.createdOn;
    return <Card style={{ marginTop: '10px' }}>
        <Card.Body>
            <Row>
                <Col style={{ fontSize: "24px", fontWeight: 'bold' }}>{s.name}</Col>
                <Col className='flex-grow-0 my-auto'>
                    <OverlayTrigger placement="right" overlay={<Tooltip>Ranking for this screen</Tooltip>} delay={{ show: 200, hide: 0 }} rootClose={true}>
                        <div style={{ borderRadius: '5px', padding: '2px 10px', color: 'white', background: '#6c757d' }}>
                            {s.rankTier.name}
                        </div>
                    </OverlayTrigger>
                </Col>
            </Row>
            <Row>
                <Col>Created</Col>
                {isUpdated && <Col>{s.isComplete ? 'Completed' : 'Updated'}</Col>}
            </Row>
            <Row>
                <Col>{s.createdOn.toLocaleString('en-us', dtFormatOptions)}</Col>
                {isUpdated && <Col>{s.updatedOn.toLocaleString('en-us', dtFormatOptions)}</Col>}
            </Row>
            <Button variant="primary" key={s.id} style={{ display: 'block', width: '100%', marginTop: '5px' }}
                onClick={() => { props.onScreenSelected(s) }}>
                <div style={{ margin: "5px" }}>
                    {s.isComplete ? 'View Summary' : 'Continue'}
                </div>
            </Button>
        </Card.Body>
    </Card>;
}
export default ScreenCard;