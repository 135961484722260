import {
  Dupont,
  Stack,
  Screen,
  Score,
  ParseScreen,
  RankTier,
  UserPreferences,
  parseUserPreferences,
  ParseStack,
  ScoresByStack,
  ParseScoreByStack,
  ParseDupont,
  parseUserRole,
  UserRole,
} from "core/models"
const apiUrl = process.env.REACT_APP_API_URL

enum ApiMethod {
  GET,
  POST
}

/// Fetches data from an endpoint, returns the response body as json if status is [OK].
async function tryFetchJson(params: {
  url: string,
  method?: ApiMethod,
  body?: any,
  accept?: string,
}): Promise<any> {
  try {
    console.debug(`API ----- ${(params.method == null || params.method == ApiMethod.GET) ? 'GET' : 'POST'}: ${params.url}`);
    const options: RequestInit = (params.method == ApiMethod.POST) ? {
      method: 'POST',
      headers: {
        'Accept': (params.accept) ? params.accept : 'application/json',
        'Content-Type': 'application/json',
      },
      body: (params.body) ? JSON.stringify(params.body) : undefined,
    } : {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    };

    const response = await fetch(params.url, options);
    if (response.ok) {
      return await response.json();
    }
  } catch (e) {
    console.error(e);
  }
  return undefined;
}

export async function fetchStacks(): Promise<Array<Stack>> {
  const json = await tryFetchJson({ url: `${apiUrl}/stacks/getActive` });
  if (json == undefined) {
    return [];
  }
  let ret = new Array<Stack>();
  json.forEach((x: any) => ret.push(ParseStack(x)));
  return ret;
}

export async function addStack(name: string, stackTypeId: number): Promise<Stack | undefined> {
  const json = await tryFetchJson({ url: `${apiUrl}/stacks/add?name=${name}&stackTypeId=${stackTypeId}`, method: ApiMethod.POST });
  if (json == undefined) {
    return undefined;
  }
  return ParseStack(json);
}

export async function uploadDupont(stackId: number, ticker: string, file: File): Promise<Dupont | undefined> {
  var data = new FormData();
  data.append('file', file);

  const response = await fetch( 
    `${apiUrl}/stacks/uploadDupont?stackId=${stackId}&ticker=${ticker}`, 
    {
      method: 'POST', 
      body: data
    }
  );
  if (response.ok) {
    return ParseDupont(await response.json());
  }else{
    return undefined;
  }
}

export async function finalizeStack(stackId: number): Promise<Stack | undefined>  {
  const json = await tryFetchJson( { url: `${apiUrl}/stacks/finalizeStack?stackId=${stackId}`, method: ApiMethod.POST, } );
  if(json == undefined) { 
    return undefined;
  } else {
    return ParseStack(json);
  }
}

export async function fetchStack(stackId: number): Promise<Stack | undefined> {
  const json = await tryFetchJson({ url: `${apiUrl}/stacks/getById?stackId=${stackId}` });
  if (json == undefined) {
    return undefined;
  }
  return ParseStack(json);
}

export async function fetchRankTiers(): Promise<Array<RankTier>> {
  const ret: Array<RankTier> = await tryFetchJson({ url: `${apiUrl}/RankTiers` });
  return ret;
}

export async function fetchScreens(stackId: number): Promise<Array<Screen> | undefined> {
  let json = await tryFetchJson({ url: `${apiUrl}/screens/getByStackId?stackId=${stackId}` });
  if (json) {
    let ret = new Array<Screen>();
    json.forEach((x: any) => ret.push(ParseScreen(x)));
    return ret;
  }
  /// on BadRequest, (Stack) NotFound, return undefined
  return undefined;
}

export async function fetchScreen(screenId: number): Promise<Screen | undefined> {
  let json = await tryFetchJson({ url: `${apiUrl}/screens/getById?screenId=${screenId}` });
  if (json) {
    return ParseScreen(json);
  }
  return undefined;
}

export async function fetchStackScoreSummary(stackId: number): Promise<Array<ScoresByStack> | undefined> {
  let json = await tryFetchJson({ url: `${apiUrl}/stacks/getScoreSummary?stackId=${stackId}` });
  if (json) {
    let ret = new Array<ScoresByStack>();
    json.forEach((x: any) => ret.push(ParseScoreByStack(x)));
    return ret;
  }
  return undefined;
}

export async function addScreen(stackId: number, name: string, rankTierId: number, user: string): Promise<Screen> {
  const json = await tryFetchJson({
    url: `${apiUrl}/screens/add?stackId=${stackId}&name=${name}&rankTierId=${rankTierId}&user=${user}`,
    method: ApiMethod.POST,
  });
  if (json) {
    return ParseScreen(json);
  } else {
    throw new Error('Could not add screen');
  }
}

export async function addScreenFromResults(name: string, dupontIds: Array<number>, stackId: number, rankTierId: number, user: string): Promise<Screen> {
  let ret;
  const json = await tryFetchJson({
    url: `${apiUrl}/screens/addSubsequentScreen?stackId=${stackId}&name=${name}&rankTierId=${rankTierId}&user=${user}`,
    method: ApiMethod.POST,
    body: dupontIds,
  });
  if (json) {
    ret = ParseScreen(json);
  } else {
    throw Error('Could not add screen');
  }
  return ret;
}

export async function fetchDuponts(screenId: number): Promise<Array<Dupont>> {
  let ret = Array<Dupont>();
  const json = await tryFetchJson({ url: `${apiUrl}/duponts/GetByScreen?screenId=${screenId}` });
  json?.forEach((x: any) => {
    ret.push(ParseDupont(x));
  })
  return ret;
}

export async function fetchDupontPdf(stackId: number, dupontId: number): Promise<Response> {
  const fetchOptions: RequestInit = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    }
  };
  return await fetch(
    getDupontUrl(stackId, dupontId),
    fetchOptions
  );
}

export function getDupontUrl(stackId: number, dupontId: number): string {
  return `${process.env.REACT_APP_API_URL}/Duponts/GetPDF?stackId=${stackId}&dupontId=${dupontId}`;
}

export async function fetchScores(screenId: number): Promise<Map<number, Score>> {
  const json = await tryFetchJson({ url: `${apiUrl}/scores?screenId=${screenId}` });
  return parseScoresMap(json);
}

function parseScoresMap(json: any): Map<number, Score> {
  let map = new Map<number, Score>();
  for (const prop in json) {
    if (json.hasOwnProperty(prop)) {
      const key = Number(prop);
      const value = json[prop];
      map.set(key, value);
    }
  }
  return map;
}

export async function postScore(screenId: number, dupontId: number, rankId: number): Promise<Map<number, Score>> {
  const json = await tryFetchJson({
    url: `${apiUrl}/scores/update?screenId=${screenId}&dupontId=${dupontId}&rankId=${rankId}`,
    method: ApiMethod.POST,
  });
  return parseScoresMap(json);
}

export async function postScreen(screenId: number): Promise<Screen> {
  let ret;
  const json = await tryFetchJson({
    url: `${apiUrl}/screens/complete?screenId=${screenId}`,
    method: ApiMethod.POST,
  });
  if (json) {
    ret = ParseScreen(json);
  } else {
    throw Error('Could not post screen');
  }
  return ret;
}

export async function fetchUserPreferences(objectId: string): Promise<UserPreferences | undefined> {
  const json = await tryFetchJson({ url: `${apiUrl}/user/GetPreferences?objectId=${objectId}` });
  if (json == undefined) {
    return undefined;
  }
  return parseUserPreferences(json);
}

export async function postUserPreferences(objectId: string, userName: string, preferences: UserPreferences): Promise<UserPreferences> {
  let ret;
  const json = await tryFetchJson({
    url: `${apiUrl}/user/SavePreferences?objectId=${objectId}&userName=${userName}&zoomPercentage=${preferences.zoom}&zoomScale=${preferences.scale}&fitToWidth=${preferences.fitToWidth}`,
    method: ApiMethod.POST,
  });
  if (json) {
    ret = parseUserPreferences(json);
  } else {
    throw Error('Could not update User Preferences');
  }
  return ret;
}

export async function fetchUserRoles(objectId: string):Promise<Array<UserRole>> {
  let ret = new Array<UserRole>();
  const json = await tryFetchJson({
    url: `${apiUrl}/user/GetUserRole?objectId=${objectId}`,
    method: ApiMethod.GET,
  });
  if(json){
    json?.forEach((x: any) => {
      ret.push(parseUserRole(x));
    })
  }
  return ret;
}

export async function postArchiveStack(stackId: number):Promise<boolean> {
  const response = await fetch( 
    `${apiUrl}/stacks/archiveStack?stackId=${stackId}`, 
    {method: 'POST'}
  );
  return (response.ok);
}