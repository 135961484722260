import { Configuration } from "@azure/msal-browser";

const AzureActiveDirectoryAppClientId: any = process.env.REACT_APP_CLIENT_ID;
const AzureActiveDirectoryTenantId: any = process.env.REACT_APP_TENANT_ID;

export const MSAL_CONFIG: Configuration = {
  auth: {
    clientId: AzureActiveDirectoryAppClientId,
    //use authority to allow single tenant app registration
    authority: 'https://login.microsoftonline.com/' + AzureActiveDirectoryTenantId,
  },
  //Set session as browser storage instead of cookies
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
  //Log data that isn't considered personal
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        // switch (level) {
        //   case LogLevel.Error:
        //     console.error(message);
        //     return;
        //   case LogLevel.Info:
        //     console.info(message);
        //     return;
        //   case LogLevel.Verbose:
        //     console.debug(message);
        //     return;
        //   case LogLevel.Warning:
        //     console.warn(message);
        //     return;
        // }
      },
    },
  },
};
export default MSAL_CONFIG;