import { useContext, useState } from "react";
import Button from 'react-bootstrap/Button';
import { Alert, Col, Container, Modal, Nav, Row } from "react-bootstrap";
import { Stack, UserRole } from "core/models";
import { useKeyBindings } from "core/key-bindings";
import { UserContext } from "core/user-account";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { postArchiveStack } from "api/api";

//currently rendering all the stack buttons all the same
interface iStackList {
  stacks: Array<Stack>;
  onStackSelected(stack: Stack): any;
  refreshStacks(): any;
  enableKeyBindings: boolean;
}
interface iStackListState {
  navKeys: Array<string>;
  activeNavKey: string;
  groupedByType: Map<string, Array<Stack>>;
  renderedStacks: Array<Stack>;
}

/// TabbedStackList
export const TabbedStackList = (props: iStackList) => {
  const [listState, setState] = useState<iStackListState>(() => {
    let state: iStackListState = {
      navKeys: new Array<string>(),
      activeNavKey: '',
      groupedByType: new Map<string, Array<Stack>>(),
      renderedStacks: new Array<Stack>(),
    };
    if (props.stacks && props.stacks.length > 0) {
      // group stacks by their type (i.e. Weekly, Blind, Default)
      let groupedByType = new Map<string, Array<Stack>>();
      let existing: Array<Stack> | undefined;
      for (var stack of props.stacks) {
        existing = groupedByType.get(stack.stackType.name);
        if (existing) {
          existing.push(stack);
        } else {
          existing = new Array(stack);
          groupedByType.set(stack.stackType.name, existing);
        }
      }

      let navKeys = Array.from(groupedByType.keys());
      let activeNavKey = navKeys.find(x => x == 'Default') ?? navKeys[0];
      let renderedStacks = groupedByType.get(activeNavKey) ?? listState.renderedStacks;
      state = {
        navKeys,
        activeNavKey,
        groupedByType,
        renderedStacks,
      };
    }
    return state;
  });
  useKeyBindings((event: KeyboardEvent) => {
    if (props.enableKeyBindings) {
      // Show current selection in list
      if (event.key == 'ArrowLeft' || event.key == 'ArrowRight') {
        if (event.key == 'ArrowLeft') {
          var index = listState.navKeys.indexOf(listState.activeNavKey);
          if (index > 0) {
            onSelectNavTab(listState.navKeys[index - 1]);
          }
        } else if (event.key == 'ArrowRight') {
          var index = listState.navKeys.indexOf(listState.activeNavKey);
          if (index < listState.navKeys.length - 1) {
            onSelectNavTab(listState.navKeys[index + 1]);
          }
        }
      }
    }
  });

  function onSelectNavTab(key: string | null) {
    if (key && listState.activeNavKey != key) {
      let stacks = listState.groupedByType.get(key);
      if (stacks) {
        setState({
          ...listState,
          renderedStacks: stacks,
          activeNavKey: key,
        });
      }
    }
  }

  return (
    <div style={{ maxWidth: '600px', marginTop: '10px' }}>
      <Nav variant="tabs" activeKey={listState.activeNavKey} onSelect={onSelectNavTab}>
        {listState.navKeys?.map((key: string) =>
          <Nav.Item key={key}>
            <Nav.Link eventKey={key} style={{ fontSize: '20px' }}>{key}</Nav.Link>
          </Nav.Item>
        )}
      </Nav>
      <StackList stacks={listState.renderedStacks} onStackSelected={props.onStackSelected} enableKeyBindings={props.enableKeyBindings} refreshStacks={props.refreshStacks}/>
    </div>
  );
}

/// StackList
export const StackList = (props: iStackList) => {
  const stacksExist = ((props.stacks?.length ?? 0) > 0);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const user = useContext(UserContext);
  const isAdmin = user.roles.some(x => x.roleId === 1);
  const [modalOpen, setModalOpen] = useState(false);
  const [stackToArchive, setStackToArchive] = useState<Stack | undefined>();

  // should we keep this here or move it to the page level component? 
  useKeyBindings((event: KeyboardEvent) => {
    if (props.enableKeyBindings && stacksExist) {
      // Show current selection in list
      if (event.key == 'ArrowDown' || event.key == 'ArrowUp') {
        let newIndex = selectedIndex;
        if (event.key == 'ArrowDown') {
          newIndex = Math.min(props.stacks!.length - 1, selectedIndex + 1);
        } else if (event.key == 'ArrowUp') {
          newIndex = Math.max(0, selectedIndex - 1);
        }
        if (newIndex != selectedIndex) {
          setSelectedIndex(newIndex);
        }
      }
      // Select stack
      else if (event.key == 'Enter') {
        if (selectedIndex >= 0) {
          props.onStackSelected(props.stacks[selectedIndex]!);
        }
      }
      // Deselect current selection
      else if (event.key == 'Escape') {
        if (selectedIndex != -1) {
          setSelectedIndex(-1);
        }
      }
    }
  });

  function clearModalSettings() {
    setStackToArchive(undefined); 
    setModalOpen(false);
  }

  function tryArchiveStack() {
    if (stackToArchive) {
      postArchiveStack(stackToArchive.id)
        .then((success) => {
          if (success) {
            clearModalSettings();
            props.refreshStacks();
          } else {
            alert('An error occurred please try again')
          }
        });
    }
  }

  return (stacksExist) ? (
    <div style={{ marginTop: '20px' }}>
      {props.stacks?.map((s: Stack, index: number) =>
        <Row>
          <Col style={{ paddingRight: '0', marginRight: '0' }}>
            <Button key={s.id}
              onClick={() => { props.onStackSelected(s) }}
              style={{
                marginBottom: 5,
                display: "block",
                width: '100%',
                backgroundColor: (index == selectedIndex) ? '#0b5ed7' : undefined,
              }} size="lg"
            >
              <div>
                <div>{s.name}</div>
                <span>Created on {s.createdOn.toLocaleDateString()}</span>
              </div>
            </Button>
          </Col>
          {/* If the user is an admin user allow them to hide stacks */}
          {isAdmin && (
            <Col className='flex-grow-0 my-auto'>
              <Button onClick={(e) => { e.stopPropagation(); setStackToArchive(s); setModalOpen(true); }} variant='tertiary' style={{ outline: '1px solid grey' }} >
                <FontAwesomeIcon icon={faEyeSlash} style={{ fontSize: '20px', textAlign: 'left' }} />
              </Button>
            </Col>
          )}
        </Row>
      )}
      <Modal show={modalOpen} onHide={() => setModalOpen(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Archive Stack?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div>
              Do you want to archive this stack ({stackToArchive?.name})?
            </div>
            <div>
              Created: {stackToArchive?.createdOn.toLocaleDateString()}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={(_) => clearModalSettings()}>Cancel</Button>
          <Button variant="primary" onClick={(_) => tryArchiveStack()}>Archive Stack</Button>
        </Modal.Footer>
      </Modal>
    </div>
  ) : (
    <Container style={{ paddingLeft: 0, marginLeft: 0 }}>
      <Alert variant="danger" className="justify-content-start">No Stacks Available</Alert>
    </Container>
  );
}