import { useEffect, useState } from "react";
import Button from 'react-bootstrap/Button';
import { fetchRankTiers } from "api/api";
import { RankTier, Score } from "core/models";
import LoadingSpinner from "core/loading-spinner";
import { Card, Col, Row } from "react-bootstrap";
import { ToolTipLabel } from "core/tool-tip-label";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

/** Form Input for adding a new screen, consists of:
 *  - New screen name (Text Input)
 *  - {@link RankTier} Dropdown with data from {@link fetchRankTiers} 
 *  - List of {@link Score} Objects associated with the selected {@link RankTier} 
 *  - Cancel button that triggers the onCancel callback 
 *  - Ok button which */
const NewScreenForm = (props: {
    onAddScreen: (screenName: string, rankTierId: number) => Promise<void>
    onCancel: () => void;
    children?: React.ReactNode;
    validateChildren?: (() => boolean);
}) => {
    const [isAdding, setIsAdding] = useState<boolean>(false);
    const [newScreenName, setNewScreenName] = useState<string>('');
    const [rankTiers, setRankTiers] = useState<Array<RankTier>>([]);
    const [rankTier, setRankTier] = useState<RankTier | undefined>();
    const [screenNameIsEmpty, setScreenNameError] = useState<boolean>(false);

    useEffect(() => {
        fetchRankTiers()
            .then((fetched) => {
                setRankTiers(fetched);
                setRankTier(fetched[0]);
            });
    }, []);

    function onSelectRankTier(e: any) {
        var found = rankTiers.find(x => x.id == parseInt(e.target.value));
        if (found) {
            setRankTier(found);
        }
    }

    async function addScreenClick() {
        setScreenNameError(false);
        if (newScreenName.length == 0) {
            // display the screen name validation error
            setScreenNameError(true);
        } else if (props.validateChildren != null && !(props.validateChildren())) {
            // let child class handle the validation errors
        } else {
            setIsAdding(true);
            props.onAddScreen(newScreenName, rankTier?.id ?? 0)
                .catch((e) => {
                    // setErrorMessage('Error adding screen, please try again.');
                    console.error(e);
                })
                .finally(() => {
                    setIsAdding(false);
                });
        }
    }

    console.debug('NewScreenForm.tsx');
    return (
        <Card style={{ padding: '12px', background: '#00000038', maxWidth: 500 }}>
            <h2>New Screen</h2>
            <div>
                Create a new screen for this stack.
            </div>
            {/* Name */}
            <Row>
                <Col className='flex-grow-0' style={{ paddingRight: 0 }}>
                    <ToolTipLabel label='Name' tooltip='Pick a name for your new screen' fontColor={(screenNameIsEmpty) ? '#dc3545' : undefined} icon={faQuestionCircle}/>
                </Col>
                <Col className='col-8'>
                    <input type="text"
                        value={newScreenName}
                        onChange={(e) => {
                            if (e.target.value == '') {
                                setScreenNameError(true);
                            }
                            setNewScreenName(e.target.value);
                        }}
                        disabled={isAdding}
                        style={{ borderColor: (screenNameIsEmpty) ? '#dc3545' : undefined }}
                    />
                    {screenNameIsEmpty && (
                        <div style={{ color: '#dc3545' }}>Enter a name for your new screen</div>
                    )}
                </Col>
            </Row>
            {/* RankTier Selection */}
            <Row>
                <Col className='flex-grow-0' style={{ paddingRight: 0 }}>
                    <ToolTipLabel label='Type' tooltip='This determines the possible scores for your new screen' icon={faQuestionCircle}/>
                </Col>
                <Col className='my-auto flex-grow-0' style={{ paddingRight: 0 }}>
                    <select onChange={onSelectRankTier} disabled={isAdding}>
                        {rankTiers.map(({ id, name }) =>
                            <option key={id} value={id}>{name}</option>
                        )}
                    </select>
                </Col>
                <Col className='col-4 my-auto flex-grow-0'>
                    (
                    {rankTier?.ranks.map(({ value }, index, arr) => {
                        return `${((index > 0 && index <= (arr.length - 1)) ? ', ' : '')}` + value;
                    })}
                    )
                </Col>
            </Row>
            { /* Form Actions */}
            {isAdding ? (
                <LoadingSpinner />
            ) : (
                <div>
                    {props.children}
                    <Button variant="secondary" onClick={props.onCancel} style={{ marginRight: 5 }} disabled={isAdding}>CANCEL</Button>
                    <Button onClick={addScreenClick} disabled={isAdding}>OK</Button>
                </div>
            )}
        </Card>
    );
}

export default NewScreenForm;