import ReactDOM from 'react-dom';
import 'index.css';
import reportWebVitals from 'reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import { MsalProvider } from "@azure/msal-react";
import AppAuth from "auth/app-auth";
import AzureAuthenticationContext from "auth/azure-auth-context";

// Azure client context
const authContext: AzureAuthenticationContext = new AzureAuthenticationContext();

ReactDOM.render(
  <MsalProvider instance={authContext.msal}>
      <AppAuth />
  </MsalProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();