import { MsalAuthenticationResult, MsalAuthenticationTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { useEffect, useState } from "react";
import LoadingSpinner from "core/loading-spinner";
import App from "core/app";
import { BrowserRouter } from "react-router-dom";

const AuthError = ({ error }: MsalAuthenticationResult) =>
    <p>An Error Occurred: {error?.errorMessage}</p>;

const AuthLoading = () =>
    <p>Authentication in progress...</p>;

type AppAuthState = {
    token: string;
    accountName: string;
    objectId: string;
}

const AppAuth = () => {
    const { instance, accounts, inProgress } = useMsal();
    const [auth, setAuth] = useState<AppAuthState|undefined>();

    //User Logged In, acquire graph access token
    useEffect(() => {
        // on start up the MSAL provider will try to get the correct auth token, changing the [inProgress] variable from 'startup' -> 'handleRedirect' -> 'none' 
        if (inProgress == 'none' && instance && (accounts?.length ?? 0 > 0)) {
            let account = accounts[0];
            if (auth?.token != undefined) {
                console.debug('AppAuth ----- Graph token already exists, user is authenticated');
            } else {
                let scopes = [
                    'https://graph.microsoft.com/.default',
                    // 'https://graph.microsoft.com/files.readwrite',
                ];
                console.debug('AppAuth ----- Acquiring new Token', scopes);
                instance.acquireTokenSilent({
                    scopes: scopes,
                    account: account,
                }).then((response) => {
                    if (response) {
                        console.debug('AppAuth ----- Token Response', response);
                        setAuth({
                            token: response.accessToken,
                            accountName: account.name ?? '',
                            objectId: account.homeAccountId.split('.')[0],
                        });
                    }
                }).catch((e) => {
                    console.error(e);
                });
            }
        }
    }, [inProgress, instance, accounts]);

    return <div id="App">
        <UnauthenticatedTemplate>
            Signing In
        </UnauthenticatedTemplate>
        <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={{ scopes: ["openid", "profile"] }}
            errorComponent={AuthError}
            loadingComponent={AuthLoading}>
            {(auth?.token != undefined)
                ? <BrowserRouter>
                    <App token={auth.token} user={auth.accountName} objectId={auth.objectId} />
                  </BrowserRouter>
                : <LoadingSpinner />
            }
        </MsalAuthenticationTemplate>
    </div>;
}
export default AppAuth;