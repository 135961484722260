import { OverlayTrigger, Tooltip } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

export const ToolTipLabel = (props: { label: string, tooltip: string, fontColor?: string | undefined, icon?: IconProp | undefined }) => {
    return (
        <OverlayTrigger placement="top" delay={{ hide: 200, show: 300 }} overlay={<Tooltip>{props.tooltip}</Tooltip>} rootClose={true}>
            <div style={{ display: 'flex' }}>
                <label style={{ padding: '4px 0', color: props.fontColor, whiteSpace: 'nowrap' }}>{props.label}</label>
                {props.icon && (
                    <FontAwesomeIcon icon={faQuestionCircle} className='my-auto'
                        style={{
                            fontSize: '10px', textAlign: 'left',
                            color: 'gray',
                            marginLeft: '5px',
                        }} />
                )}
            </div>
        </OverlayTrigger>
    );
}