import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dupont } from "core/models";
import { useContext, useRef, useState } from "react";
import { Button, Modal, ListGroup, OverlayTrigger, Tooltip } from "react-bootstrap";
import { DupontContext, RatingPageState } from "./rating-page";
import { useKeyBindings } from "core/key-bindings";

const DupontSearchControl = (props: {
    ratingPageDispatch: (action: any) => void,
    pageState: RatingPageState,
}) => {
    const duponts = useContext(DupontContext);
    const [modalOpen, setModalOpen] = useState(false);
    const [searchResults, setSearchResults] = useState<Array<Dupont>>([]);
    const inputRef = useRef<HTMLInputElement>(null);


    function searchChanged(e: any) {
        var results = duponts.filter(x => x.ticker.toLowerCase().includes(e.target.value.toLowerCase().trim()));
        if (results && results.length > 0) {
            results = results.slice(0, 10);
            results = results.sort((a, b) => {
                return a.ticker.localeCompare(b.ticker);
            });
            setSearchResults(results);
        }
        else {
            setSearchResults([]);
        }
    }

    useKeyBindings((event: KeyboardEvent) => {
        if (props.pageState.keyBindingsEnabled) {
            if (event.key == 'p') {
                event.preventDefault();
                showModal();
            }
        }
    });

    function dispatchDupont(dupont: Dupont) {
        props.ratingPageDispatch({ type: 'load_dupont', payload: dupont });
    }

    function showModal() {
        setModalOpen(true);
        props.ratingPageDispatch({ type: 'toggle_keybindings', payload: false });
        inputRef.current!.focus();
    }

    function hideModal() {
        setModalOpen(false);
        props.ratingPageDispatch({ type: 'toggle_keybindings', payload: true });
    }

    return (
        <>
            <OverlayTrigger placement="bottom" overlay={<Tooltip>Dupont Search</Tooltip>} delay={{ show: 200, hide: 0 }} rootClose={true}>
                <div style={{ alignItems: 'center', marginTop: 'auto', marginBottom: 'auto', marginRight: '5px' }}>
                    <Button variant="secondary" onClick={showModal} >
                        <FontAwesomeIcon icon={faSearch} style={{ fontSize: '20px' }} />
                    </Button>
                </div>
            </OverlayTrigger>
            <Modal show={modalOpen} onHide={hideModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Search Duponts</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <input type="text" onChange={searchChanged} ref={inputRef} />
                    {(searchResults.length > 0) ? (
                        <ListGroup>
                            {searchResults.map((x) => {
                                return <ListGroup.Item key={x.id} onClick={() => {
                                    dispatchDupont(x);
                                    hideModal();
                                }}>
                                    {x.ticker}
                                </ListGroup.Item>
                            })}
                        </ListGroup>
                    ) : <div>No Results Found</div>
                    }
                </Modal.Body>
            </Modal>
        </>
    );
}

export default DupontSearchControl;