import { memo, useContext, useEffect, useState } from 'react';
import { Document, Page, pdfjs, PDFPageProxy } from 'react-pdf';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { UserPreferencesContext } from './user_preference_context';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
console.debug('pdfjs version: ' + pdfjs.version);

export interface iDupontView {
    stackId: number;
    dupontId: number;
    onDocLoadSuccess: any;
    pageNumber: number;
}

const DupontView = (props: iDupontView) => {
    const userPreferences = useContext(UserPreferencesContext);
    // The current scale of the pdf page, changes when the window resizes if autoFit is enabled.
    const [pageScale, setPageScale] = useState(userPreferences.scale);
    // The width of the pdf page, this gets set after the pdf renders.
    const [pageWidth, setPageWidth] = useState(0);
    // The current referenced window size, updates if the window's width change is above the windowResizeThreshold.
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const windowResizeThreshold = 100; 

    useEffect(() => {
        //Set up window resize listener
        function handleResize() {
            if (userPreferences.fitToWidth && Math.abs(window.innerWidth - windowWidth) > windowResizeThreshold) {
                console.debug('window width resized', window.innerWidth);
                setWindowWidth(window.innerWidth);
            }
        }
        window.addEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if(userPreferences.fitToWidth && pageWidth > 0){
            let newScale = (windowWidth / pageWidth) * 0.75;
            if (pageScale != newScale) {
                console.debug('windowWidthChanged - resizing pdf scale to fit width', newScale);
                setPageScale(newScale);
            }
        }
    }, [pageWidth, windowWidth])

    useEffect(()=>{
        if(userPreferences.fitToWidth && pageWidth > 0) {
            let newScale = (windowWidth / pageWidth) * 0.75;
            if (pageScale != newScale) {
                console.debug('appPrefChanged - resizing pdf scale to fit width', newScale);
                setPageScale(newScale);

            }
        } else if(!userPreferences.fitToWidth && pageScale != userPreferences.scale) {
            console.debug('appPrefChanged - resizing pdf scale to user pref', userPreferences.scale);
            setPageScale(userPreferences.scale);
        }
    }, [userPreferences]);

    const onPageLoad = (page: PDFPageProxy) => {
        if(pageWidth != page.originalWidth){
            console.debug('pdf loaded -- setting pageWidth', page.originalWidth);
            setPageWidth(page.originalWidth);
        }
    }

    console.debug('DupontView ----- Render', {windowWidth: windowWidth, pageScale:pageScale});
    return (
        <div id="pdfDoc">
            <Document file={{ url: `${process.env.REACT_APP_API_URL}/duponts/getpdf?stackId=${props.stackId}&dupontId=${props.dupontId}` }} onLoadSuccess={props.onDocLoadSuccess} >
                <Page  object-fit='auto' pageNumber={props.pageNumber} scale={pageScale} onLoadSuccess={onPageLoad} />
            </Document>
        </div>
    );
}
export default memo(DupontView);