import { Screen, Dupont, Rank, Stack, Score, UserPreferences } from 'core/models';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClipboardCheck, faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import PdfPageControls from 'rating/pdf-page-controls';
import DupontControls from 'rating/dupont-controls';
import ScorePanel from 'rating/score-panel';
import { Col, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { RatingPageState } from 'rating/rating-page';
import { fetchScores, postScore } from 'api/api';
import PdfZoomControls from './pdf-zoom-controls';
import { useEffect, useState } from 'react';
import { useKeyBindings } from 'core/key-bindings';

export interface iRatingHeader {
    stack: Stack;
    screen: Screen;
    dupont: Dupont;
    pageState: RatingPageState;
    ratingPageDispatch: (action: any) => void;
    onBackButtonPressed: () => void;
    onSummaryButtonPressed: () => void;
    onLoadDupontById: (id: number) => void;
}

interface iContinueModal {
    show: boolean;
    unscoredDupontId: number;
}

const RatingHeader = (props: iRatingHeader): JSX.Element => {
    const ranks = props.pageState.screen!.rankTier.ranks;
    const [readyForInput, setReadyForInput] = useState(false);
    const [scores, setScores] = useState<Map<number, Score>>(new Map<number, Score>());
    const [modalState, setModalState] = useState<iContinueModal>({ show: false, unscoredDupontId: 0 });
    useEffect(() => {
        fetchScores(props.screen.id)
            .then((scores) => {
                if (scores) {
                    setScores(scores);
                    setReadyForInput(true);
                    // If revisiting an uncomplete screen, ask to continue from where they left off
                    if ((!props.pageState.screen?.isComplete) && props.pageState.stack?.stackType.name.trim().toLowerCase() != 'weekly' && scores.size > 0) {
                        var currDupont: Dupont;
                        for (var i = 0; i < props.pageState.duponts.length; i++) {
                            currDupont = props.pageState.duponts[i];
                            if (!scores.has(currDupont.id)) {
                                props.ratingPageDispatch({ type: 'toggle_keybindings', payload: false });
                                setModalState({
                                    show: true,
                                    unscoredDupontId: currDupont.id,
                                });
                                break;
                            }
                        }
                    }
                }
            });
    }, []);

    useKeyBindings((event: KeyboardEvent) => {
        if (readyForInput) {
            if (modalState.show && event.key == 'Enter') {
                onResumeSessionClick();
            } else if (props.pageState.keyBindingsEnabled) {
                // Decrement Score
                if (event.key == 'q' && scores.has(props.dupont.id)) {
                    var currRankIndex = ranks.indexOf(ranks.find((val) => val.id == scores.get(props.dupont.id)!.rankId)!);
                    //if there is a previous score value
                    if (currRankIndex > 0) {
                        updateScore(ranks[currRankIndex - 1], false);
                    }
                }
                // Increment Score
                else if (event.key == 'e') {
                    if (!scores.has(props.dupont.id)) {
                        updateScore(ranks[0], false); //set score to the first option
                    } else {
                        //find the next score value 
                        var currRankIndex = ranks.indexOf(ranks.find((val) => val.id == scores.get(props.dupont.id)!.rankId)!);
                        if (currRankIndex < (ranks.length - 1)) {
                            updateScore(ranks[currRankIndex + 1], false);
                        }
                    }
                }
            }
        }
    });

    function getNextUnscoredDupont(): Dupont | null {
        var currDupont = null;
        var currUnscored = null;
        for (var i = props.pageState.dupontIndex + 1; i < props.pageState.duponts.length; i++) {
            currDupont = props.pageState.duponts[i];
            if (!scores.has(currDupont.id)) {
                currUnscored = currDupont;
                break;
            }
        }
        return currUnscored;
    }

    function updateScore(rank: Rank, navToNext: boolean = true) {
        props.ratingPageDispatch({ type: 'toggle_keybindings', payload: false });
        postScore(props.screen.id, props.dupont.id, rank.id)
            .then((scores) => {
                console.debug('posted score: ' + rank.value, new Date().toLocaleTimeString());
                setScores(scores);
                // wait a bit to display the scoring change, then move to the next dupont
                if (navToNext) {
                    setTimeout(() => props.ratingPageDispatch({ type: 'next_dupont' }), 500);
                }
            })
            .catch((e) => {
                console.error(e);
            }).finally(() => {
                props.ratingPageDispatch({ type: 'toggle_keybindings', payload: true });
            });
    }

    function hideContinueModal() {
        setModalState({ ...modalState, show: false });
        props.ratingPageDispatch({ type: 'toggle_keybindings', payload: true });
    }

    function onResumeSessionClick() {
        hideContinueModal();
        props.onLoadDupontById(modalState.unscoredDupontId);
    }

    let dt = props.dupont.fileLastModified;
    let isBlind = props.stack.stackType.id == 2;
    let showTicker = props.screen.isComplete || !isBlind; //show ticker if study is not blind or if already completed
    let showScoringPanel = props.stack.stackType.id != 3; //if not [Weekly] stack type
    console.debug('RatingHeader', props);
    return (
        <>
            <Container style={{ backgroundColor: 'lightgray', padding: '10px', maxWidth: '100%' }} fluid>
                <Row noGutters style={{ alignItems: 'center', margin: 0, }}>
                    <Col className='flex-grow-0' style={{ padding: '0' }}>
                        <OverlayTrigger placement="bottom" overlay={<Tooltip>Back to Screen Selection</Tooltip>} delay={{ hide: 0, show: 200 }} rootClose={true}>
                            <Button variant="secondary" size="sm" style={{ color: 'black', backgroundColor: 'lightgray', borderColor: 'lightgray' }} onClick={props.onBackButtonPressed}>
                                <FontAwesomeIcon icon={faArrowLeft} style={{ fontSize: '20px' }} />
                            </Button>
                        </OverlayTrigger>
                    </Col>
                    {/* Ticker and Date */}
                    {showTicker && (
                        <Col className='flex-grow-0' style={{ padding: '0' }}>
                            <div style={{ display: 'flex', alignItems: 'center', paddingRight: '10px', marginRight: '10px', borderRight: '1px solid grey', minHeight: '38px' }}>
                                <span style={{ paddingRight: '10px', fontSize: '20px', fontWeight: 'bold', minWidth: '145px' }}>
                                    {props.dupont.ticker}
                                </span>
                                <span style={{ textAlign: 'right' }}>
                                    {dt.getMonth() + 1}/{dt.getDate()}/{dt.getFullYear()}
                                </span>
                            </div>
                        </Col>
                    )}
                    <Col className='flex-grow-0' style={{ padding: '0' }}>
                        <PdfZoomControls ratingPageDispatch={props.ratingPageDispatch} />
                    </Col>
                    <Col className='flex-grow-0' style={{ padding: '0' }}>
                        <PdfPageControls
                            enabled={(props.pageState.numPages > 1)}
                            curr={props.pageState.pageNumber}
                            max={props.pageState.numPages}
                            ratingPageDispatch={props.ratingPageDispatch} />
                    </Col>
                    {/* Screen Progress Controls */}
                    <Col className='flex-grow-0' style={{ padding: '0' }}>
                        <DupontControls
                            dupontIndex={props.pageState.dupontIndex}
                            enableSearch={showTicker}
                            ratingPageDispatch={props.ratingPageDispatch}
                            getNextUnscoredDupont={getNextUnscoredDupont}
                            pageState={props.pageState} />
                    </Col>
                    {/* Scoring Controls */}
                    {showScoringPanel && (
                        <Col className='flex-grow-0' style={{ padding: '0' }}>
                            <div style={{ alignItems: 'center', paddingRight: '10px', marginRight: '10px', borderRight: '1px solid grey', }}>
                                <ScorePanel onScore={updateScore} disabled={props.screen.isComplete} rankTier={props.screen.rankTier} currRankId={scores.has(props.dupont.id) ? (scores.get(props.dupont.id)!.rankId) : 0} />
                            </div>
                        </Col>
                    )}
                    {/* Summary Button */}
                    <Col className='flex-grow-0' style={{ padding: '0' }}>
                        <div style={{ alignItems: 'center', marginTop: 'auto', marginBottom: 'auto', marginRight: '5px' }}>
                            <OverlayTrigger overlay={<Tooltip id='tooltip-disabled'>View Summary</Tooltip>} placement='bottom' trigger={["hover", "focus"]} delay={{ hide: 0, show: 200 }} rootClose={true}>
                                <Button variant="primary" onClick={props.onSummaryButtonPressed}  >
                                    <FontAwesomeIcon icon={faClipboardCheck} style={{ fontSize: '20px' }} />
                                </Button>
                            </OverlayTrigger>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Modal show={modalState.show} onHide={hideContinueModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Resume Session</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Do you want to continue from where you left off?
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={hideContinueModal} variant="secondary">Cancel</Button>
                    <Button onClick={onResumeSessionClick} style={{ marginLeft: '5px' }}>Continue</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default RatingHeader;