import React, { ReactElement } from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Rank, RankTier } from "core/models";

const ScorePanel = (props: {
    disabled: boolean;
    onScore: (rank: Rank) => void;
    rankTier: RankTier;
    currRankId: number;
}): JSX.Element => {
    const disabledToolTipTxt = 'The Screen has been completed, scoring is disabled';

    console.debug('ScorePanel ---- ', props);
    var btns: ReactElement = (
        <div style={{ display: 'flex' }}>
            {props.rankTier.ranks.map((rank: Rank) =>
                <ScoreButton
                    isSelected={props.currRankId == rank.id}
                    disabled={props.disabled}
                    rank={rank}
                    onClick={() => props.onScore(rank)} />
            )}
        </div>
    );

    if (props.disabled) {
        return (
            <OverlayTrigger placement={'left'} overlay={<Tooltip id='tooltip-disabled'>{disabledToolTipTxt}</Tooltip>} delay={{ show: 200, hide: 0 }} rootClose={true}>
                {btns}
            </OverlayTrigger>
        );
    } else {
        return btns;
    }
}

const ScoreButton = (props: {
    isSelected: boolean;
    onClick: () => void;
    rank: Rank;
    disabled: boolean;
}): JSX.Element => {
    function getBtnStyle(rank: Rank): React.CSSProperties {
        var pointerEventsValue = (props.disabled) ? 'none' : 'all';
        var btnStyle: any;
        if (props.isSelected) {
            btnStyle = {
                color: rank.foregroundColor,
                backgroundColor: rank.backgroundColor,
                borderColor: rank.borderColor,
                pointerEvents: pointerEventsValue,
            };
        } else {
            btnStyle = {
                pointerEvents: pointerEventsValue,
            }
        }
        return btnStyle;
    }

    return (
        <Button variant="light" style={getBtnStyle(props.rank)}
            onClick={props.onClick} disabled={props.disabled}>
            {props.rank.value}
        </Button>
    );
}
export default ScorePanel;