import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dupont } from "core/models";
import { Button, ListGroup } from "react-bootstrap";

// export const ScreenResults = (props: ScreenResultsModel) => {
//     return <SummaryListGroup {...props} />;
// }

export class ScreenResultsModel {
    constructor(
        public allDuponts: Array<Dupont> = [],
        public scoredDupontIds: Map<string, Array<number>>,
        public unscoredDupontIds: Array<number> = [],
        public totalDuponts = allDuponts.length,
    ) { }
}

export const ScreenResults = (props: {
    model: ScreenResultsModel,
    loadDupont: (dupont: Dupont) => void,
}) => {

    const model = props.model;
    return (
        <ListGroup style={{ width: '500px', marginTop: '10px', }}>
            {/* Scored Tickers */}
            {Array.from(model.scoredDupontIds).map(([score, ids]) => {
                return <>
                    <ListGroup.Item variant="primary">
                        {score} ({ids.length}/{model.totalDuponts})
                    </ListGroup.Item>
                    {model.allDuponts
                        .filter((x) => (ids.indexOf(x.id) > -1))
                        .map((dupont) => <DupontListGroupItem ticker={dupont.ticker} onLoadDupont={() => props.loadDupont(dupont)} />
                        )}
                </>
            })}
            {/* Unscored Tickers */}
            {(model.unscoredDupontIds.length > 0) && (<>
                <ListGroup.Item variant="primary">
                    Unscored ({model.unscoredDupontIds.length}/{model.totalDuponts})
                </ListGroup.Item>
                {model.unscoredDupontIds.map((id) => {
                    var dupont = model.allDuponts.find((x) => x.id == id)!;
                    return <DupontListGroupItem ticker={dupont.ticker} onLoadDupont={() => props.loadDupont(dupont)} />;
                })}
            </>)}
        </ListGroup>
    );
}

const DupontListGroupItem = (props: {
    ticker: string,
    onLoadDupont: () => void,
}) => {
    return <ListGroup.Item style={{display:'flex'}}>
        <span style={{whiteSpace:'nowrap'}}>{props.ticker}</span>
        {/* <a onClick={props.onLoadDupont}><span></span>{props.ticker}</a> */}
        <div style={{width:'100%'}}></div>
        <Button onClick={props.onLoadDupont} variant='tertiary' style={{outline:'1px solid grey'}} >
            view
            {/* <FontAwesomeIcon icon={faChevronLeft} style={{ fontSize: '20px', textAlign: 'left' }} /> */}
        </Button>
        {/* <FontAwesomeIcon icon={faChevronLeft} style={{ fontSize: '20px', textAlign: 'left' }} /> */}
    </ListGroup.Item>
}

const SummaryCard = (props: {
    score: string,
    duponts: Array<Dupont>,
}) => {
    return (
        <div className="card">
            <div className="card-header">
                {props.score} : {props.duponts.length}
            </div>
            <div className="card-body">
                <ul>
                    {props.duponts.map((d) => <li>{d.ticker}</li>)}
                </ul>
            </div>
        </div>
    );
}