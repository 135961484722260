import {
  PublicClientApplication,
  AuthenticationResult,
  AccountInfo,
  EndSessionRequest,
  RedirectRequest,
  PopupRequest,
} from "@azure/msal-browser";
import MSAL_CONFIG from "auth/azure-auth-config";

export class AzureAuthenticationContext {
  public msal: PublicClientApplication = new PublicClientApplication(
    MSAL_CONFIG
  );
  private account?: AccountInfo;
  private loginRedirectRequest?: RedirectRequest;
  private loginRequest?: PopupRequest;
  private tokenAuthResult?: AuthenticationResult;

  public isAuthenticationConfigured = false;
  get accessToken() {
    return this.tokenAuthResult?.accessToken;
  }

  constructor() {
    // @ts-ignore
    this.account = this.getAccount();
    this.setRequestObjects();
    if (MSAL_CONFIG?.auth?.clientId) {
      this.isAuthenticationConfigured = true;
    }
  }

  private setRequestObjects(): void {
    this.loginRequest = {
      scopes: [],
      prompt: "select_account",
    };

    this.loginRedirectRequest = {
      ...this.loginRequest,
      redirectStartPage: window.location.href,
    };
  }


  logout(account: AccountInfo): void {
    const logOutRequest: EndSessionRequest = { account };
    this.msal.logout(logOutRequest);
  }

  private getAccount(): AccountInfo | undefined {
    const currentAccounts = this.msal.getAllAccounts();
    if (currentAccounts == null || currentAccounts.length == 0) {
      return undefined;
    }

    return currentAccounts[0];
    // TODO: If there are multiple accounts add code to choose the account
  }
}

export default AzureAuthenticationContext;