import { useEffect } from "react";

export function useKeyBindings(onKeyDown: (event: KeyboardEvent) => void) {
    /// whenever handleKeyDown updates add/remove the dom event listener
    useEffect(() => {
        document.addEventListener('keydown', onKeyDown);

        return () => {
            document.removeEventListener('keydown', onKeyDown);
        };
    }, [onKeyDown]);
}
