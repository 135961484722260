import { Spinner } from "react-bootstrap";

const LoadingSpinner = () => {
    return (
        <span>
            <Spinner style={{ marginRight: 5 }}
                animation="border"
                role="status"
                variant="primary"
                size="sm" />
            Loading...
        </span>
    );
}
export default LoadingSpinner;