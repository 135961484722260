import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { addStack, addScreen, postScreen, uploadDupont, finalizeStack } from "api/api";
import LoadingSpinner from "core/loading-spinner";
import { StackType } from "core/models";
import { ToolTipLabel } from "core/tool-tip-label";
import { useState, useRef } from "react";
import { Card, Row, Col, Button } from "react-bootstrap";

const PDF_EXT = '.pdf';
class DupontFile {
    public file: File;
    public ticker: string;

    constructor(file:File){
        this.ticker = this.parseTickerFromFileName(file.name);
        this.file = file;
    }

    parseTickerFromFileName(fileName: string): string {
        const FORMAT_DOMESTIC_QUARTERLY = "D_Dupont_Q_";
        const FORMAT_DOMESTIC_ANNUAL = "D_Dupont_Annual_";
        var regex = /\d{14} - (.{3,10}).*.pdf/i;
        const regexMatch = fileName.match(regex);
        // var regexIntl = new RegExp("/(?<ticker>:\\d{14} - )(.{3,10}).*.pdf/i", 'i');
        var ticker: string;
        /// The file name for these duponts look like this:
        /// D_Dupont_Q_[ticker].pdf
        /// replace "[ticker]" with the ticker value of the company
        if (fileName.startsWith(FORMAT_DOMESTIC_QUARTERLY)) {
            ticker = fileName
                .replace(FORMAT_DOMESTIC_QUARTERLY, '')
                .replace(PDF_EXT, '')
                .trim();
        }
        else if (fileName.startsWith(FORMAT_DOMESTIC_ANNUAL)) {
            ticker = fileName
                .replace(FORMAT_DOMESTIC_ANNUAL, '')
                .replace(PDF_EXT, '')
                .trim();
        }
        /// The file name for these duponts look like  this:
        /// 20210722144252 - [ticker].pdf
        /// replace "[ticker]" with the ticker value of the company
        else if (regexMatch && regexMatch[1] != null) {
            ticker = regexMatch[1];
        }
        else {
            ticker = fileName.replace('.pdf', '');
        }
        return ticker;
    }
}

const NewStackForm = (props: {
    onCancel: () => void,
    onAdd: () => void,
}) => {
    // allow uploading a single stack type 
    const [name, setName] = useState<string>('');
    const [nameIsEmpty, setNameIsEmpty] = useState<boolean>(false);
    const [isAdding, setIsAdding] = useState<boolean>(false);
    const [files, setFiles] = useState<FileList | null>();
    const hiddenFileInput = useRef<HTMLInputElement>(null);
    // todo pull from API
    const stackTypes = [
        new StackType(1, 'Default', ''),
        // new StackType(2, 'Blind', 'Read only version.  Scoring is not allowed.'),
        new StackType(3, 'Weekly', 'Ticker is not shown untill the screen is completed.'),
    ];
    const [stackType, setStackType] = useState<StackType>(stackTypes[0]);

    async function onAddClicked() {
        if (!files) {
            window.alert('Please select a dupont folder to upload');
        } if (name.trim() == '') {
            window.alert('Please enter a name for your stack');
        } else {
            setIsAdding(true);

            // Validate files
            var payload = validateFiles(files!);
            if (payload.isValid) {
                // Create initial stack with no duponts
                var newStack = await addStack(name, stackType.id);
                if (newStack != null) {
                    // Attempt to auto create the screen and immediately complete it, 
                    // Weekly stacks should always only have a single screen with no scores (ReadOnly)
                    try {
                        if (stackType.id == 3) {
                            var screen = await addScreen(newStack.id, 'Review', 1, 'Auto');
                            await postScreen(screen.id);
                        }
                    } catch (error) {
                        console.error(error);
                    }

                    var dupontFiles: Array<DupontFile> = payload.data;
                    dupontFiles.sort((a, b) => 
                        a.file.lastModified - b.file.lastModified
                    );

                    // Tie the duponts to the stack
                    const maxTries = 3;
                    var uploaded = false;
                    for (let file of dupontFiles) {

                        // Try to upload the dupont atleast 3 times 
                        for (var i = 0; i < maxTries; i++) {
                            uploaded = false;
                            try {
                                var dupont = await uploadDupont(newStack?.id, file.ticker, file.file);
                                if (dupont) {
                                    uploaded = true;
                                    break;
                                }
                            } catch (e) {
                                console.error(e);
                            }
                        }

                        // Notify the user the dupont could not be uploaded and stop the upload
                        if (!uploaded) {
                            window.alert('Could not upload dupont after 3 attempts, please check your connection and try again.');
                            break;
                        }
                    }

                    // Finalize stack
                    if (uploaded && (await finalizeStack(newStack?.id))) {
                        setIsAdding(false);
                        props.onAdd();
                    }
                } else {
                    window.alert("Can't create a stack with the same name, rename your stack and try again.");
                    setIsAdding(false);
                }
            } else {
                window.alert(payload.error);
                setIsAdding(false);
            }

            //create the stack
            //upload duponts to the stack
            //finalize the stack
        }
    }

    // loop through files list and extract data to use to upload the file (Ticker, FileData)
    function validateFiles(files: FileList): any {
        var dupontFiles: Array<DupontFile> = [];

        if (files.length > 0) {
            for (var i = 0; i < files.length; i++) {
                try {
                    var fileName = files[i].name;

                    ///ensure files are all pdfs
                    if (fileName.substring(fileName.length - 4, fileName.length) != PDF_EXT) {
                        return {
                            isValid: false,
                            error: 'Please only upload PDF files.',
                        };
                    }
                    dupontFiles.push(new DupontFile(files[i]));
                } catch (e) {
                    return {
                        isValid: false,
                        error: 'An error ocurred: ' + e,
                    };
                }
            }
        } else {
            return {
                isValid: false,
                error: 'Folder has no files',
            };
        }

        return {
            isValid: true,
            data: dupontFiles,
        };
    }

    function onSelectStackType(e: any) {
        var found = stackTypes.find(x => x.id == parseInt(e.target.value));
        if (found) {
            setStackType(found);
        }
    }

    let filesExist = (files != null && files.length > 0);
    return (
        <Card style={{ padding: '12px', background: '#00000038', maxWidth: 500 }}>
            <h2>New Stack</h2>
            <div>Upload a folder of duponts to create a new stack</div>
            <div>
                NOTE - Duponts must follow the following naming convention:
                <ul>
                    <li>D_Dupont_Q_[ticker].pdf</li>
                    <li>D_Dupont_Annual_[ticker].pdf</li>
                    <li>[yyyymmddhhmmss] - [ticker].pdf</li>
                </ul>
            </div>
            {/* StackType Selection */}
            <Row>
                <Col style={{ paddingRight: 0, maxWidth: '70px' }}>
                    <ToolTipLabel label='Type' tooltip='Determines the type of stack you are uploading.' icon={faQuestionCircle}/>
                </Col>
                <Col className='my-auto flex-grow-0' style={{ paddingRight: 0 }}>
                    <select onChange={onSelectStackType} disabled={isAdding}>
                        {stackTypes.map(({ id, name }) =>
                            <option key={id} value={id}>{name}</option>
                        )}
                    </select>
                </Col>
            </Row>
            {/* Name */}
            <Row style={{ marginTop: '8px' }}>
                <Col className='flex-grow-0' style={{ paddingRight: 0 }}>
                    <ToolTipLabel label='Name' tooltip='Pick a name for your new stack. Stack names must be unique.' fontColor={(nameIsEmpty) ? '#dc3545' : undefined} icon={faQuestionCircle}/>
                </Col>
                <Col className='col-8 my-auto'>
                    <input type="text"
                        value={name}
                        onChange={(e) => {
                            if (e.target.value == '') {
                                setNameIsEmpty(true);
                            } else {
                                setNameIsEmpty(false);
                            }
                            setName(e.target.value);
                        }}
                        disabled={isAdding}
                    />
                </Col>
            </Row>
            {filesExist && (
                <Row>
                    <Col className='flex-grow-0' style={{ paddingRight: 0 }}>
                        <ToolTipLabel label='Num Duponts' tooltip='The number of files that will be uploaded' fontColor={(nameIsEmpty) ? '#dc3545' : undefined} icon={faQuestionCircle}/>
                    </Col>
                    <Col className='col-8 my-auto'>
                        {files!.length}
                    </Col>
                </Row>
            )}
            { /* Form Actions */}
            {isAdding ? (
                <LoadingSpinner />
            ) : (
                <>
                    <Row style={{ marginTop: 10 }}>
                        <Col>
                            <Button onClick={props.onCancel} variant="secondary" style={{ marginRight: 5 }} disabled={isAdding}>
                                CANCEL
                            </Button>
                            <Button onClick={() => hiddenFileInput.current?.click()} style={{ marginRight: 5 }}>
                                {(filesExist) ? 'CHANGER' : 'SELECT'} FOLDER
                            </Button>
                            {filesExist && (
                                <Button onClick={onAddClicked} disabled={isAdding || filesExist == null}>UPLOAD</Button>
                            )}
                            <input ref={hiddenFileInput}
                                type="file"
                                className="inputfile"
                                style={{ display: 'none' }}
                                /* @ts-expect-error */
                                directory=""
                                webkitdirectory=""
                                onChange={(e) => {
                                    const folderName = e.target.files![0].webkitRelativePath.split('/')[0];
                                    setName(folderName);
                                    setFiles(e.target.files);
                                }}
                            />
                        </Col>
                    </Row>
                </>
            )}
        </Card>
    );
}
export default NewStackForm;  
