import { faLongArrowAltDown, faLongArrowAltUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/esm/OverlayTrigger";

const PdfPageControls = (props: {
    enabled: boolean;
    curr: number;
    max: number;
    ratingPageDispatch: (action: any) => void;
}): JSX.Element => {
    function prevOnClick() {
        if (props.curr > 1) {
            props.ratingPageDispatch({ type: 'decrement_pdf_page' });
        }
    }

    function nextOnClick() {
        if (props.curr < props.max) {
            props.ratingPageDispatch({ type: 'increment_pdf_page' });
        }
    }

    //props.ratingPageDispatch({type: 'set_pdf_page', payload:num})}
    return (
        <div style={{ display: 'flex', alignItems: 'center', paddingRight: '10px', marginLeft: '10px', marginRight: '10px', borderRight: '1px solid grey', minWidth: '85px', maxWidth: '100px', minHeight: '38px' }}>
            {(props.enabled)
                ? (
                    <div style={{ display: 'flex', alignItems: 'center', paddingRight: '10px', minWidth: '70px', maxWidth: '100px', }}>
                        <OverlayTrigger placement="bottom" overlay={<Tooltip>Prev Page</Tooltip>} delay={{ show: 200, hide: 0 }} rootClose={true}>
                            <FontAwesomeIcon
                                icon={faLongArrowAltUp}
                                style={{ fontSize: '20px', marginRight: '5px' }} cursor='pointer'
                                onClick={prevOnClick} />
                        </OverlayTrigger>
                        <OverlayTrigger placement="bottom" overlay={<Tooltip>Next Page</Tooltip>} delay={{ show: 200, hide: 0 }} rootClose={true}>
                            <FontAwesomeIcon
                                icon={faLongArrowAltDown}
                                style={{ fontSize: '20px', marginRight: '8px' }} cursor='pointer'
                                onClick={nextOnClick} />
                        </OverlayTrigger>
                        <span>{props.curr} / {props.max}</span>
                    </div>
                )
                : (<span>Page {props.curr} / {props.max}</span>)
            }
        </div>
    );
}

export default PdfPageControls;