import { Client, ClientOptions } from "@microsoft/microsoft-graph-client";

export default class CustomGraphClient {
    private graphClient: Client;
    constructor(token: string) {
        const graphClientOptions: ClientOptions = {
            authProvider: {
                getAccessToken: async () => {
                    return token;
                }
            }
        };
        this.graphClient = Client.initWithMiddleware(graphClientOptions);
    }

    public getDateTimeStr(): string {
        const dtParts = new Date().toLocaleDateString().split('/');
        const month = dtParts[0].padStart(2, '0');
        const day = dtParts[1].padStart(2, '0');
        const year = dtParts[2];
        const dtStr = year + month + day;

        const localeTimeStr = new Date().toLocaleTimeString();
        const timeParts = localeTimeStr.split(' ')[0].split(':');
        let hr = Number.parseInt(timeParts[0]);
        const min = timeParts[1];
        const sec = timeParts[2];
        const meridiem = localeTimeStr.split(' ')[1];
        if (meridiem == 'PM') {
            hr += 12;
        }
        const timeStr = hr.toString() + min + sec;

        return dtStr + timeStr;
    }

    public async createOneDriveRoot(name: string): Promise<string> {
        let ret = '';
        try {
            let existing = await this.getOneDriveRoot(name);
            if (existing) {
                console.debug('Duponts folder exists');
                ret = existing.id;
            } else {
                console.debug('Creating Duponts folder');
                let response = await this.addOneDriveRoot(name);
                console.debug('Duponts folder created', response);
                ret = response.id;
            }
        } catch (e) {
            console.error(e);
        }
        return ret;
    }

    private async getOneDriveRoot(name: string): Promise<any> {
        return await this.graphClient
            .api(`/me/drive/root:/${name}`)
            .get()
            .catch((e) => console.error('Error getting the Duponts folder', e));
    }

    private async addOneDriveRoot(name: string): Promise<any> {
        const driveItem = { name, folder: {} };
        return await this.graphClient
            .api('/me/drive/root/children/')
            .post(driveItem)
            .catch((e) => console.error('Error creating the Duponts folder', e));
    }

    public async createOneDriveSubfolder(rootId: string, path: string): Promise<string | undefined> {
        let folderId = rootId;
        for (var folderName of path.split('/')) {
            let driveItem = { name: folderName, folder: {} };
            const response = await this.graphClient
                .api(`/me/drive/items/${folderId}/children/`)
                .post(driveItem)
                .catch(async (e) => {
                    if (e && e.code && e.code == 'nameAlreadyExists') {
                        const children = await this.graphClient
                            .api(`/me/drive/items/${folderId}/children/`)
                            .get()
                            .catch((innerE) => {
                                console.error(innerE);
                                return undefined;
                            });
                        const child = children.value.find((x: any) => x.name == folderName);
                        folderId = child.id;
                    } else {
                        console.error(e);
                        return undefined;
                    }
                });
            if(response){
                folderId = response.id;
            }
        }
        return folderId;
    }

    public async uploadFile(fileUrl: string, itemId: string, fileName: string): Promise<boolean> {
        let success = false;
        try {
            let pdfResponse = await fetch(fileUrl);
            let blob = await pdfResponse.blob();
            await this.graphClient
                .api(`/me/drive/items/${itemId}:/${fileName}:/content`)
                .put(blob);
            success = true;
        } catch (e) {
            console.error(`Error uploading file:(${fileName})`, e);
        }
        return success;
    }
}