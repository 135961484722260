import { useLocation } from 'react-router-dom';


export function useRouteDetails() {
    const location = useLocation();

    let stackId = 0, screenId = 0, dupontId = 0;
    let url = location.pathname, split = url.split('/');
    if (split.length > 1) {
        stackId = Number.parseInt(split[1]);
    }
    if (split.length > 2) {
        screenId = Number.parseInt(split[2]);
    }
    if (split.length > 3) {
        try {
            dupontId = Number.parseInt(split[3]);
        } catch (error) { }
    }
    return [stackId, screenId, dupontId];
}