import { CSSProperties, useRef } from "react";

const TickersByScoreChecklist = (props: {
    scoredDupontIds: Map<string, Array<number>>,
    disabled: boolean,
    selectedDuponts: Array<number>,
    onNewScreenDupontsChanged: (selection: Array<number>) => void,
    style?: CSSProperties,
}) => {

    /// Add or Remove Duponts from the current selection
    function onSelectScore(isSelected: boolean, dupontIds: Array<number>) {
        var updatedSelection = [...props.selectedDuponts];
        dupontIds.forEach((id: number) => {
            const index = updatedSelection.indexOf(id);
            const found = index >= 0;
            if (isSelected && !found) {
                updatedSelection.push(id);
            } else if (!isSelected && found) {
                updatedSelection.splice(index, 1);
            }
        });

        props.onNewScreenDupontsChanged(updatedSelection);
    }

    return (
        <div style={props.style}>
            {Array.from(props.scoredDupontIds)
                .map(([score, scoredDupontIds]) => (
                    <ScoreCheckbox
                        score={score}
                        numScoredDuponts={scoredDupontIds.length}
                        onChanged={(isSelected: boolean) => onSelectScore(isSelected, scoredDupontIds)}
                        disabled={props.disabled} />
                ))}
        </div>
    );
}

const ScoreCheckbox = (props: {
    score: string,
    numScoredDuponts: number,
    disabled: boolean,
    onChanged: (isSelected: boolean) => void,
}) => {
    const inputRef = useRef<HTMLInputElement>(null);

    return (
        <div key={props.score}>
            <input ref={inputRef} type="checkbox" disabled={props.disabled} onChange={(e) => props.onChanged(e.target.checked)} />
            <label style={{ marginLeft: '5px' }} onClick={() => inputRef.current?.click()} >{props.score}: {props.numScoredDuponts}</label>
        </div>
    );
}
export default TickersByScoreChecklist;