import { memo } from "react";
import { Breadcrumb } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useRouteDetails } from "./route_details";

const PageBreadcrumbs = ({ }) => {
    const [stackId, screenId] = useRouteDetails();
    const navigate = useNavigate();
    // only show the breadCrumbs on the SelectScreen and Summary pages
    return (
        <Breadcrumb style={{ marginBottom: '0' }}>
            <Breadcrumb.Item href="#" key='home'>
                <span onClick={() => navigate('/')}>Select Stack</span>
            </Breadcrumb.Item>
            {(screenId == 0) &&
                <Breadcrumb.Item key='screenSelect' active>
                    <span onClick={() => navigate(`/${stackId}`)}>Select Screen</span>
                </Breadcrumb.Item>}
            {(screenId > 0) && (
                <>
                    <Breadcrumb.Item key='screenSelect'>
                        <span onClick={() => navigate(`/${stackId}`)}>Select Screen</span>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item key='screen'>
                        <span onClick={() => navigate(`/${stackId}/${screenId}`)}>Rating</span>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item key='summary' active>
                        Summary
                    </Breadcrumb.Item>
                </>
            )}
        </Breadcrumb>
    );
}
export default memo(PageBreadcrumbs);