import { useState } from "react";
import { Screen } from "core/models";
import ScreenCard from "screen/screen-card";
import { Nav } from "react-bootstrap";
import { useKeyBindings } from "core/key-bindings";

/** Displays screens in tabs, grouped by completion status [In Progress, Completed */
//todo don't hard code the status values
const TabbedScreenList = (props: { screens: Array<Screen>, onScreenSelected: (screen: Screen) => void; }) => {
    const navKeys = ['New', 'Complete'];
    const [activeNavKey, setActiveNavKey] = useState<string>(navKeys[0]);

    useKeyBindings((event: KeyboardEvent) => {
        // Show current selection in list
        if (navKeys.length > 1) {
            if (event.key == 'ArrowLeft') {
                var index = navKeys.indexOf(activeNavKey);
                if (index > 0) {
                    onSelectNavTab(navKeys[index - 1]);
                }
            } else if (event.key == 'ArrowRight') {
                var index = navKeys.indexOf(activeNavKey);
                if (index < navKeys.length - 1) {
                    onSelectNavTab(navKeys[index + 1]);
                }
            }
        }
    });

    function onSelectNavTab(key: string | null) {
        if (key) {
            setActiveNavKey(key);
        }
    }

    return (
        <div>
            <Nav variant="tabs" activeKey={activeNavKey} onSelect={(key) => onSelectNavTab(key)}>
                <Nav.Item>
                    <Nav.Link eventKey={'New'} style={{ fontSize: '20px' }}>In Progress</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey={'Complete'} style={{ fontSize: '20px' }}>Complete</Nav.Link>
                </Nav.Item>
            </Nav>
            <ScreenList screens={props.screens.filter(x => x.status == activeNavKey)} onScreenSelected={props.onScreenSelected} />
        </div>
    );
}

/** Displays screens as an array of {@link ScreenCard}, calling @param onScreenSelected when the screen card is clicked.*/
const ScreenList = (props: { screens: Array<Screen>, onScreenSelected: (screen: Screen) => void; }) => {
    if (props.screens.length == 0) {
        return <div style={{ margin: '10px' }}>No Existing Screens</div>;
    } else {
        return (
            <div>
                {(props.screens.length == 0) ? (
                    <div style={{ margin: '10px' }}>No Screens</div>
                ) : props.screens?.map((s: Screen) =>
                    <ScreenCard screen={s} onScreenSelected={props.onScreenSelected} key={s.id} />
                )}
            </div>
        );
    }
}

export default TabbedScreenList;