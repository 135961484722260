import { useContext, useEffect, useReducer } from "react";
import { Screen, Dupont } from "core/models";
import Button from 'react-bootstrap/Button';
import { UserContext } from "core/user-account";
import { addScreenFromResults, fetchScreen, fetchDuponts, fetchScores, postScreen, fetchDupontPdf } from "api/api";
import LoadingSpinner from "core/loading-spinner";
import PageBreadcrumbs from "core/page-breadcrumbs";
import TickersByScoreChecklist from "./tickers-by-score-checklist";
import NewScreenForm from "screen/new-screen-form";
import { ScreenResults, ScreenResultsModel } from "./sceen_results";
import { useRouteDetails } from "core/route_details";
import { useNavigate } from "react-router-dom";
import JSZip from "jszip";
import { saveAs } from 'file-saver';

/// The possible views shown when the screen has been completed
enum SummaryPageViews {
    Loading,
    Error,
    Incomplete,          // screen has not been marked 'Completed'
    TickersByScore,      // Tickers are listed next to the score they were given
    CreateNewScreenForm, // A form to create a new screen based on current screen scores
    UploadToOneDriveForm,// A form to upload scored duponts to the user's OneDrive
}

class SummaryPageState {
    constructor(
        public view: SummaryPageViews = SummaryPageViews.Loading,
        public screen: Screen | undefined = undefined,
        public newScreenDuponts: Array<Dupont> = [], //todo move to newScreenform? 
        public results: ScreenResultsModel = new ScreenResultsModel([], new Map<string, Array<number>>(), []),
        public newScreenDupontIdsError: boolean = false,
        public newScreenDupontIds: Array<number> = newScreenDuponts.map<number>((x) => x.id),
    ) { }

    get loading() {
        return this.view == SummaryPageViews.Loading;
    }
}

const SummaryPage = () => {
    const navigate = useNavigate();
    const user = useContext(UserContext);
    const [stackId, screenId] = useRouteDetails();
    const [pageState, summaryPageDispatch] = useReducer(summaryReducer, new SummaryPageState())

    useEffect(() => {
        Promise.all([
            fetchDuponts(screenId),
            fetchScores(screenId),
            fetchScreen(screenId),
        ]).then(([duponts, scores, screen]) => {

            if (screen == undefined) {
                summaryPageDispatch({ type: 'page_load_error', payload: {} });
                return;
            }
            let dupontIdsByScore = new Map<string, Array<number>>();
            var unscoredDuponts = duponts.map((d) => d.id);
            for (let [dupontId, score] of scores) {
                const dupont = duponts.find(x => x.id == dupontId)!;
                unscoredDuponts.splice(unscoredDuponts.indexOf(dupont.id), 1);
                console.debug('unscoredDuponts after splice', unscoredDuponts);
                if (dupontIdsByScore.has(score.value)) {
                    dupontIdsByScore.set(score.value, [
                        ...dupontIdsByScore.get(score.value)!,
                        dupont.id,
                    ]);
                } else {
                    dupontIdsByScore.set(score.value, [dupont.id]);
                }
            }
            summaryPageDispatch({
                type: 'page_loaded',
                payload: {
                    view: (screen.isComplete) ? SummaryPageViews.TickersByScore : SummaryPageViews.Incomplete,
                    results: new ScreenResultsModel(duponts, dupontIdsByScore, unscoredDuponts),
                    screen,
                }
            });
        });
    }, []);

    function summaryReducer(state: SummaryPageState, action: any): SummaryPageState {
        switch (action.type) {
            case 'page_load_error': {
                return {
                    ...state,
                    loading: false,
                    view: SummaryPageViews.Error,
                }
            }
            case 'page_loaded': {
                return {
                    ...state,
                    results: action.payload.results,
                    view: action.payload.view,
                    loading: false,
                    screen: action.payload.screen,
                };
            }
            case 'change_view': {
                return {
                    ...state,
                    view: action.payload,
                    loading: false,
                }
            }
            case 'set_loading': {
                return {
                    ...state,
                    loading: action.payload,
                };
            }
            case 'new_screen_duponts_changed': {
                return {
                    ...state,
                    loading: false,
                    newScreenDupontIds: action.payload,
                };
            }
            case 'validate_new_screen_duponts': {
                return {
                    ...state,
                    loading: false,
                    newScreenDupontIdsError: action.payload,
                };
            }
        }
        return state;
    }

    function onScreenSelected(selected: Screen) {
        navigate(`/${stackId}/${selected.id}`);
    }

    function onChangeView(newView: SummaryPageViews) {
        summaryPageDispatch({ type: 'change_view', payload: newView });
    }

    function onNewScreenDupontsChanged(selection: Array<number>) {
        summaryPageDispatch({ type: 'new_screen_duponts_changed', payload: selection });
    }

    function loadDupont(dupont: Dupont) {
        navigate(`/${stackId}/${screenId}/${dupont.id}`);
    }

    return <div style={{ padding: '12px' }}>
        <PageBreadcrumbs />
        {/* Header */}
        {(pageState.screen != null && pageState.view != SummaryPageViews.CreateNewScreenForm) && <>
            <h1>{pageState.screen.name} Summary</h1>
            <div style={{ fontSize: '14px', paddingBottom: '10px' }}>
                <div>Created by {pageState.screen.createdBy} on {pageState.screen.createdOn.toLocaleDateString()} - {pageState.screen.createdOn.toLocaleTimeString()}</div>
                <div>{(pageState.screen.isComplete) ? 'Completed on' : 'Last updated'} {pageState.screen.updatedOn.toLocaleDateString()} - {pageState.screen.updatedOn.toLocaleTimeString()}</div>
            </div>
        </>}
        {renderBody()}
    </div>;

    function renderBody() {
        /// SummaryPageViews.Incomplete
        switch (pageState.view) {
            case SummaryPageViews.Incomplete: {
                return (<>
                    <div>To prevent any further edits to your screen, mark it as complete</div>
                    <div>Once a screen is complete, you can create subsequent screens from the scores.</div>
                    <Button onClick={completeScreen}>
                        Complete Screen
                    </Button>
                    <ScreenResults model={pageState.results} loadDupont={loadDupont} />
                </>);
            }
            case SummaryPageViews.TickersByScore: {
                return (<>
                    <div style={{ width: '500px' }}>
                        <Button onClick={() => onChangeView(SummaryPageViews.CreateNewScreenForm)} >
                            Create Screen from Scores
                        </Button>
                        <Button onClick={() => onChangeView(SummaryPageViews.UploadToOneDriveForm)} style={{ marginLeft: '5px' }}>
                            Download Duponts
                        </Button>
                    </div>
                    <ScreenResults model={pageState.results} loadDupont={loadDupont} />
                </>);
            }
            case SummaryPageViews.CreateNewScreenForm: {
                return (<>
                    <NewScreenForm
                        onCancel={() => onChangeView(SummaryPageViews.TickersByScore)}
                        onAddScreen={onAddScreen}>
                        <div style={{ marginTop: '20px' }}>
                            <h6 style={{ color: (pageState.newScreenDupontIdsError) ? 'red' : undefined }}>
                                Included Scores
                            </h6>
                            {(pageState.newScreenDupontIdsError) && (
                                <div style={{ color: 'red' }}>Select at least one of the below scores</div>
                            )}
                            <span>Duponts from this screen that were given the selected scores will be added to the new screen for further review</span>
                            <TickersByScoreChecklist
                                scoredDupontIds={pageState.results.scoredDupontIds}
                                selectedDuponts={pageState.newScreenDupontIds}
                                disabled={pageState.loading}
                                onNewScreenDupontsChanged={onNewScreenDupontsChanged}
                                style={{ marginBottom: '15px' }} />
                        </div>
                    </NewScreenForm>
                </>);
            }
            case SummaryPageViews.UploadToOneDriveForm: {
                return (<>
                    <TickersByScoreChecklist
                        scoredDupontIds={pageState.results.scoredDupontIds}
                        selectedDuponts={pageState.newScreenDupontIds}
                        disabled={pageState.loading}
                        onNewScreenDupontsChanged={onNewScreenDupontsChanged} />
                    {(pageState.loading) ? (
                        <LoadingSpinner />
                    ) : (
                        <div>
                            <Button variant='secondary' onClick={() => onChangeView(SummaryPageViews.TickersByScore)} style={{ marginRight: '5px' }}>CANCEL</Button>
                            <Button onClick={downloadDuponts} disabled={pageState.newScreenDupontIds.length == 0}>Download</Button>
                        </div>
                    )}
                </>);
            }
        }
    }

    function setLoading(isLoading: boolean) {
        summaryPageDispatch({ type: 'set_loading', payload: isLoading });
    }

    async function completeScreen() {
        setLoading(true);
        postScreen(screenId)
            .then((_) => {
                onChangeView(SummaryPageViews.TickersByScore);
            })
            .catch((e) => {
                console.error(e);
                window.alert('Could not complete screen, please try again');
                setLoading(false);
            });
    }

    async function onAddScreen(screenName: string, rankTierId: number) {
        setLoading(true);
        if (pageState.newScreenDupontIds.length <= 0) {
            summaryPageDispatch({ type: 'validate_new_screen_duponts', payload: true });
        } else {
            addScreenFromResults(screenName, pageState.newScreenDupontIds, stackId, rankTierId, user.userName)
                .then((added) => onScreenSelected(added))
                .catch((e) => {
                    console.error(e);
                    window.alert('Error adding screen, please try again.');
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }

    async function downloadDuponts() {
        setLoading(true);

        var stackName = pageState.screen!.stackName;
        var screenName = pageState.screen!.name;

        var zip = JSZip();
        zip.file('ReadMe.txt', `The folders organize Duponts by their assigned score from the DupontReviewApp. These scores were assigned from the stack [${stackName}] and screen [${screenName}].`)
        // loop through array of dupont ids to download
        for (var i = 0; i < pageState.newScreenDupontIds.length; i++) {
            // find the dupont based on the score it was given
            for (let [score, dupontIds] of pageState.results.scoredDupontIds.entries()) {
                var id = pageState.newScreenDupontIds[i];
                if (dupontIds.includes(id)) {
                    // Place the dupont PDF in an appropriate score folder
                    var response = await fetchDupontPdf(stackId, id);
                    var dupont = pageState.results.allDuponts.find(x => x.id == id)!;
                    zip.folder(score)?.file(dupont.fileName, response.blob());
                    break;
                }
            }
        }

        var dt = new Date(Date.now());
        var dateStr = dt.getFullYear() + (dt.getMonth() + 1).toString().padStart(2, '0') + dt.getDate().toString().padStart(2, '0');
        zip.generateAsync({ type: 'blob' }).then((blob) => {
            saveAs(blob, `${stackName}_${screenName}_${dateStr}.zip`);
        });

        setLoading(false);
    }
}

export default SummaryPage;