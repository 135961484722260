import RatingHeader from 'rating/rating-header';
import DupontView from 'rating/dupont-view';
import { createContext, useContext, useEffect, useReducer } from 'react';
import { Dupont, Stack, Screen, UserPreferences } from 'core/models';
import { fetchScreen, fetchStack, fetchUserPreferences, fetchDuponts, postUserPreferences } from 'api/api';
import ErrorBoundary from 'core/error-boundary';
import LoadingSpinner from 'core/loading-spinner';
import { useNavigate } from 'react-router-dom';
import { useRouteDetails } from 'core/route_details';
import { UserPreferencesContext } from './user_preference_context';
import { UserContext } from 'core/user-account';
import { useKeyBindings } from 'core/key-bindings';

export class RatingPageState {
    constructor(
        public loading: boolean = true,
        public duponts: Array<Dupont> = [],
        public stack: Stack | undefined = undefined,
        public screen: Screen | undefined = undefined,
        public dupontIndex: number = 0,
        public numPages: number = 0,
        public pageNumber: number = 1,
        public preferences: UserPreferences = new UserPreferences(),
        public keyBindingsEnabled: boolean = true,
    ) { }
}

export const DupontContext = createContext<Array<Dupont>>([]);

const RatingPage = () => {
    const user = useContext(UserContext);
    const navigate = useNavigate();
    const [stackId, screenId, dupontId] = useRouteDetails();
    const [pageState, ratingPageDispatch] = useReducer(ratingReducer, new RatingPageState());

    // On component mounted, fetch the entity details
    useEffect(() => {
        console.debug('RatingPage ----- Mounted, fetching duponts');
        Promise.all([
            fetchDuponts(screenId),
            fetchStack(stackId),
            fetchScreen(screenId),
            fetchUserPreferences(user.objectId)
        ])
            .then(([duponts, stack, screen, preferences]) => {
                ratingPageDispatch({ type: 'page_loaded', payload: { duponts, stack, screen, preferences } });
            })
            .catch(() => {
                ratingPageDispatch({ type: 'page_load_error', payload: null });
            });
    }, []);

    function ratingReducer(state: RatingPageState, action: any): RatingPageState {
        switch (action.type) {
            case 'page_loaded': {
                // If this is the first time a user is opening the page, they will have no saved preferences. Save the default values.
                if (action.payload.preferences == undefined) {
                    postUserPreferences(user.objectId, user.userName, state.preferences);
                }

                let duponts: Array<Dupont> = action.payload.duponts;
                var dupontIndex = 0;
                if (dupontId > 0) {
                    var found = duponts.find(x => x.id == dupontId);
                    if (found) {
                        dupontIndex = duponts.indexOf(found);
                    }
                }
                return {
                    ...state,
                    duponts: duponts,
                    stack: action.payload.stack,
                    screen: action.payload.screen,
                    preferences: action.payload.preferences ?? new UserPreferences(),
                    loading: false,
                    dupontIndex: dupontIndex,
                }
            }
            case 'page_load_error': {
                return {
                    ...state,
                    loading: false,
                }
            }
            case 'pdf_loaded': {
                if (state.numPages != action.payload) {
                    return {
                        ...state,
                        numPages: action.payload,
                    };
                }
                break;
            }
            case 'update_user_preferences': {
                postUserPreferences(user.objectId, user.userName, action.payload);
                return {
                    ...state,
                    preferences: action.payload,
                }
            }
            case 'increment_pdf_page': {
                return {
                    ...state,
                    pageNumber: state.pageNumber + 1,
                };
            }
            case 'decrement_pdf_page': {
                return {
                    ...state,
                    pageNumber: state.pageNumber - 1,
                };
            }
            case 'set_pdf_page': {
                return {
                    ...state,
                    pageNumber: Math.max(1, Math.min(state.numPages, action.payload)),
                };
            }
            case 'previous_dupont': {
                return {
                    ...state,
                    dupontIndex: Math.max(state.dupontIndex - 1, 0),
                    pageNumber: 1,
                };
            }
            case 'next_dupont': {
                //only change state if this isn't the final dupont
                var newIndex = state.dupontIndex + 1;
                if (newIndex <= state.duponts.length - 1) {
                    return {
                        ...state,
                        dupontIndex: newIndex,
                        pageNumber: 1,
                    };
                }
                break;
            }
            case 'load_dupont': {
                if (action.payload) {
                    let index = state.duponts.indexOf(action.payload);
                    if (index > -1) {
                        return {
                            ...state,
                            dupontIndex: index,
                            pageNumber: 1,
                        };
                    }
                }
                break;
            }
            case 'dupont_set_index': {
                return {
                    ...state,
                    dupontIndex: Math.max(0, Math.min(state.duponts.length - 1, action.payload)),
                    pageNumber: 1,
                };
            }
            case 'toggle_keybindings': {
                return {
                    ...state,
                    keyBindingsEnabled: action.payload,
                }
            }
        }
        return state;
    }

    function onPdfLoadSuccess(doc: any) {
        ratingPageDispatch({ type: 'pdf_loaded', payload: doc.numPages });
    }

    function backNav() {
        navigate(-1);
    }

    function navigateToSummary() {
        navigate(`/${stackId}/${screenId}/summary`);
    }

    useKeyBindings((event: KeyboardEvent) => {
        // Show current selection in list
        if (pageState.keyBindingsEnabled) {
            // Change Active Duponts
            if (event.key == 'ArrowLeft' || event.key == 'a') {
                ratingPageDispatch({ type: 'previous_dupont' });
            } else if (event.key == 'ArrowRight' || event.key == 'd') {
                ratingPageDispatch({ type: 'next_dupont' });
            }
            // Change PDF Pages 
            else if (event.key == 'ArrowUp' || event.key == 'w') {
                if (pageState.pageNumber > 1) {
                    ratingPageDispatch({ type: 'decrement_pdf_page' });
                }
            } else if (event.key == 'ArrowDown' || event.key == 's') {
                if (pageState.pageNumber < pageState.numPages) {
                    ratingPageDispatch({ type: 'increment_pdf_page' });
                }
            }
        }
    });

    console.log(`RatingPage ----- ${(pageState.loading) ? 'Loading' : 'Render'}`, pageState);
    if (pageState.loading) {
        return <LoadingSpinner></LoadingSpinner>;
    } else if (pageState.duponts.length == 0) {
        return <div>There was a problem getting the duponts, please reload the app and try again.</div>
    } else if (pageState.stack == null) {
        return <div>Invalid Stack, please try again with a valid stack.</div>
    } else if (pageState.screen == null) {
        return <div>Invalid Screen, please try again with a valid screen.</div>
    }
    let currDupont = pageState.duponts[pageState.dupontIndex];
    return (
        <DupontContext.Provider value={pageState.duponts}>
            <UserPreferencesContext.Provider value={pageState.preferences}>
                <div>
                    <ErrorBoundary>
                        <RatingHeader
                            stack={pageState.stack} screen={pageState.screen} dupont={currDupont}
                            pageState={pageState} ratingPageDispatch={ratingPageDispatch}
                            onBackButtonPressed={backNav} onSummaryButtonPressed={navigateToSummary}
                            onLoadDupontById={(id: number) => {
                                var found = pageState.duponts.find((dupont) => dupont.id == id);
                                if (found) {
                                    ratingPageDispatch({ type: 'load_dupont', payload: found });
                                }
                            }} />
                    </ErrorBoundary>
                    <ErrorBoundary>
                        <DupontView stackId={pageState.stack.id} dupontId={currDupont.id} onDocLoadSuccess={onPdfLoadSuccess} pageNumber={pageState.pageNumber} />
                    </ErrorBoundary>
                </div>
            </UserPreferencesContext.Provider>
        </DupontContext.Provider>
    );
}
export default RatingPage;