import { faChevronLeft, faChevronRight, faStepForward } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ChangeEvent, FocusEvent, useContext, useEffect, useState } from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { DupontContext } from "./rating-page";
import { Dupont } from "core/models";

const DupontIndexControl = (props: {
    dupontIndex: number;
    ratingPageDispatch: (action: any) => void;
    getNextUnscoredDupont: () => Dupont | null;
}) => {
    const duponts = useContext(DupontContext);
    const [dupontIndexInput, setDupontIndexInput] = useState<string>((props.dupontIndex + 1).toString());

    useEffect(() => {
        let newDupontNum = props.dupontIndex + 1;
        if (parseInt(dupontIndexInput) != newDupontNum) {
            console.debug('DupontControls --- useEffect updating', { curr: props.dupontIndex, dupontIndexInput });
            setDupontIndexInput(newDupontNum.toString());
            console.debug('use effect changing to ' + props.dupontIndex);
        }
    }, [props.dupontIndex]);

    /// Called when changing the current dupont index manually
    function inputOnChange(e: ChangeEvent<HTMLInputElement>) {
        console.debug('DupontControls ----- InputChange', e.target.value);
        if (e.target.value == '') {
            setDupontIndexInput(e.target.value);
        } else {
            var parsed = parseFloat(e.target.value);
            if (parsed) {
                setDupontIndexInput(parsed.toString());
            } else {
                e.preventDefault();
            }
        }
    }

    /// Called when the input field loses focus
    function inputOnBlur(_: FocusEvent<HTMLInputElement>) {
        console.debug('DupontControls ----- InputBlur');
        var dupontNum = parseInt(dupontIndexInput);
        if (dupontNum) {
            //apply max/min
            dupontNum = Math.min(Math.max(dupontNum, 1), duponts.length);
            dispatchDupontIndex(dupontNum - 1);
        } else {
            //invalid number, reset to current index
            dupontNum = props.dupontIndex + 1;
        }
        setDupontIndexInput(dupontNum.toString());
    }

    /// Blur the input when the user presses the enter key
    function onKeyDown(e: any) {
        //on enter press
        if (e.keyCode == 13) {
            e.target.blur();
        }
    }

    function dispatchPrevDupont() {
        props.ratingPageDispatch({ type: 'previous_dupont' });
    }

    function dispatchNextDupont() {
        props.ratingPageDispatch({ type: 'next_dupont' });
    }

    function dispatchNextUnscoredDupont() {
        var nextUnscored = props.getNextUnscoredDupont();
        if (nextUnscored != null) {
            props.ratingPageDispatch({ type: 'load_dupont', payload: nextUnscored });
        }
    }

    function dispatchDupontIndex(index: number) {
        props.ratingPageDispatch({ type: 'dupont_set_index', payload: index });
    }

    return (<>
        <DupontIndexButton
            onClick={dispatchPrevDupont}
            disabled={props.dupontIndex == 0}
            style={{ marginRight: '8px', textAlign: 'left' }}
            icon={<FontAwesomeIcon icon={faChevronLeft} style={{ fontSize: '20px', textAlign: 'left' }} />}
            toolTip="Prev Dupont"
        />
        {/* The dupont index control - using a text field to display an empty string */}
        <OverlayTrigger placement="bottom" overlay={<Tooltip>Prev Dupont</Tooltip>} delay={{ show: 200, hide: 0 }} rootClose={true}>
            <>
                <input type="text"
                    value={dupontIndexInput}
                    onBlur={inputOnBlur} onChange={inputOnChange}
                    onKeyDown={onKeyDown}
                    style={{ width: '20%', maxWidth: '500px', minWidth: '50px', marginRight: '5px' }}
                />
                <span>/{duponts.length}</span>
            </>
        </OverlayTrigger>
        <DupontIndexButton
            onClick={dispatchNextDupont}
            disabled={props.dupontIndex == (duponts.length - 1)}
            style={{ marginLeft: '8px', marginRight: '5px', textAlign: 'right' }}
            icon={<FontAwesomeIcon icon={faChevronRight} style={{ fontSize: '20px', textAlign: 'right' }} />}
            toolTip="Next Dupont"
        />
        <DupontIndexButton
            onClick={dispatchNextUnscoredDupont}
            disabled={props.dupontIndex == (duponts.length - 1)}
            style={{ marginRight: '5px', textAlign: 'right' }}
            icon={<FontAwesomeIcon icon={faStepForward} style={{ fontSize: '20px', textAlign: 'right' }} />}
            toolTip="Next Unscored Dupont"
        />

    </>);
}

const DupontIndexButton = (props: {
    onClick: () => void,
    disabled: boolean,
    icon: JSX.Element,
    style: any,
    toolTip: string,
}) => {
    return (
        <OverlayTrigger placement="bottom" overlay={<Tooltip>{props.toolTip}</Tooltip>} delay={{ show: 200, hide: 0 }} rootClose={true}>
            <Button onClick={props.onClick}
                disabled={props.disabled}
                variant='light'
                style={props.style}>
                {props.icon}
            </Button>
        </OverlayTrigger>
    );
}

export default DupontIndexControl;